import { get } from './request';
import store from '../store';

const getLanguage = () => store.state.i18n.language;

export default {
  getJobOfferSeo ({ citySlug, partnerSlug }) {
    return get(`/seo/${getLanguage()}/job-offer?city_slug=${citySlug}&partner_slug=${partnerSlug}`, { target: 'bse-api' });
  },

  getJobOffersSeo (offerIds) {
    return get(`/seo/${getLanguage()}/job-offers-seo-data?ids=${offerIds}`, { target: 'bse-api' });
  },

  async getOffersSeoData (offers) {
    if (!offers.length) {
      return [];
    }
    // START LOCALHOST BSE WORKAROUND
    const offerIds = offers.map(offer => offer.id);
    const offersSeo = await this.getJobOffersSeo(offerIds);
    // const offersSeo = []; // comment this line before merging to master
    // END LOCALHOST BSE WORKAROUND

    offers.forEach(offer => {
      const OfferSeoData = offersSeo.find(offerSeo => offerSeo.jobOfferId === offer.id);
      if (OfferSeoData) {
        offer.seoTitle = OfferSeoData.seoTitle;
        offer.cardTitle = OfferSeoData.cardTitle;
      }
    });

    return offers;
  },

  getCityCategorySeo ({ citySlug, categorySlug }) {
    return get(`/seo/${getLanguage()}/city-category?category_slug=${categorySlug}&city_slug=${citySlug}`, { target: 'bse-api' });
  },

  getCitySeo (citySlug) {
    return get(`/seo/${getLanguage()}/city?city_slug=${citySlug}`, { target: 'bse-api' });
  },

};
