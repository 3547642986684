import partnerService from '@/js/services/partner';
import citiesService from '@/js/services/cities';

const mutations = {
  startLoadingPartners (stateObj) {
    stateObj.isLoadingPartners = true;
  },

  stopLoadingPartners (stateObj) {
    stateObj.isLoadingPartners = false;
  },

  startLoadingPartnerCities (stateObj) {
    stateObj.isLoadingPartnerCities = true;
  },

  stopLoadingPartnerCities (stateObj) {
    stateObj.isLoadingPartnerCities = false;
  },

  setPartner (stateObj, partner) {
    stateObj.partner = partner;
  },

  setPartners (stateObj, partners) {
    stateObj.partners = partners;
  },

  setPartnerCities (stateObj, cities) {
    stateObj.partnerCities = cities;
  },

  setFilteredCategory (stateObj, category) {
    stateObj.filteredCategory = category;
  },

  clearFilteredCategory (stateObj) {
    stateObj.filteredCategory = null;
  },
};

const actions = {
  getPartner: async ({ commit, state }, { partnerSlug }) => {
    if (state.partner && state.partner.slug === partnerSlug) {
      return;
    }
    commit('setPartner', await partnerService.getPartnerBySlug(partnerSlug));
  },

  getPartners: async ({ commit }, params) => {
    if (!params) {
      return;
    }

    commit('startLoadingPartners');
    commit('setPartners', await partnerService.getPartners(params));
    commit('stopLoadingPartners');
  },

  async getPartnerCities ({ commit, state }, { partnerId, countryCode }) {
    if (state.isLoadingPartnerCities) {
      return;
    }

    commit('startLoadingPartnerCities');
    commit('setPartnerCities', await citiesService.getNearbyCities({
      partner_id: partnerId,
      country_code: countryCode,
    }));
    commit('stopLoadingPartnerCities');
  },

  setFilteredCategory ({ commit }, category) {
    commit('setFilteredCategory', category);
  },

  clearFilteredCategory ({ commit }) {
    commit('clearFilteredCategory');
  },
};

const state = {
  isLoadingPartners: false,
  isLoadingPartnerCities: false,
  partner: null,
  partners: null,
  partnerCities: [],
  filteredCategory: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
