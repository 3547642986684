import { render, staticRenderFns } from "./reviews-segment.vue?vue&type=template&id=06f67848&scoped=true&"
import script from "./reviews-segment.vue?vue&type=script&lang=js&"
export * from "./reviews-segment.vue?vue&type=script&lang=js&"
import style0 from "./reviews-segment.vue?vue&type=style&index=0&id=06f67848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f67848",
  null
  
)

export default component.exports