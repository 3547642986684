export default [
  {
    name: 'profile',
    path: 'profile',
    component: () => import('@/js/views/Profile/profile-view'),

    redirect: () => ({ name: 'profile-user' }),

    children: [
      {
        name: 'profile-user',
        path: 'user',
        component: () => import('@/js/views/Profile/UserData'),
      },
      {
        name: 'profile-user-edit',
        path: 'user/edit',
        component: () => import('@/js/views/Profile/UserDataEdit'),
      },
      {
        name: 'profile-employers',
        path: 'employers',
        component: () => import('@/js/views/Profile/EmployersData'),
      },
    ],
  },
];
