<template>
  <div
    ref="dropdown"
    class="ui dropdown"
    :class="{
      'multiple': isMultiselect,
      'search': isSearchable,
      'inline': isInline,
      'selection': isSearchable || isMultiselect || isClassic,
      'active visible': opened,
    }"
    @click="handleMainElementClick"
  >
    <select
      :name="name"
      v-bind="isMultiple"
    >
      <option
        v-for="item in list"
        :key="item.id"
        :value="item.id"
        :selected="select(item, preselect)"
      >
        {{ item.name }}
      </option>
    </select>

    <aj-input
      v-if="isSearchable"
      id="city-selector"
      ref="search"
      v-model="searchValue"
      special
      :icon-left="icon"
      :placeholder="placeHolderSentence"
      autocomplete="off"
      tabindex="0"
      @input="search"
      @keyup.esc="close"
      @keyup.down="moveDown"
      @keyup.up="moveUp"
      @keydown.enter="openSelected"
    />
    <!--https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion-->
    <template v-if="isMultiselect">
      <template v-for="activeItem in list">
        <multiselect-active-item
          v-if="activeItem._select"
          :key="activeItem.id"
          :data-value="activeItem.id"
          @remove="itemRemove($event)"
        >
          {{ activeItem.name }}
        </multiselect-active-item>
      </template>
    </template>

    <flag-icon
      v-if="isLinks && isMobile"
      :country-code="languageCode"
    />
    <div
      v-if="showLabel"
      v-show="!searchValueLength"
      class="default text"
      @click.self="toggle"
      v-text="label"
    />
    <div
      v-if="selectedItem"
      v-show="!searchValueLength"
      class="text"
      @click.self="toggle"
    >
      <flag-icon
        v-if="countryCode(selectedItem)"
        :country-code="countryCode(selectedItem)"
      >
        {{ selectedItem.name }}
      </flag-icon>
      <template v-else>
        {{ selectedItem.name }}
      </template>
    </div>

    <div
      class="menu transition"
      :class="{ 'visible': opened }"
      :style="menuStyle"
    >
      <div
        v-if="listWarning"
        class="header"
      >
        <aj-icon
          shape="exclamation"
          class="circle icon"
        />
        {{ listWarning }}
      </div>
      <template v-if="nonEmptyList">
        <div
          v-if="listWarning"
          class="divider"
        />
        <div
          v-if="listHeader"
          class="header"
        >
          {{ listHeader }}
        </div>
        <template v-if="isLinks">
          <a
            v-for="item in list"
            :key="item.id"
            class="item"
            :class="{
              'active': select(item, preselect),
            }"
            :href="item.uri"
            :data-value="item.id"
            :title="item.title"
            @click.prevent="itemSelect($event, item)"
          >
            {{ item.name }}
          </a>
        </template>
        <template v-else>
          <div
            v-for="(item, index) in list"
            :key="item.id"
            class="item"
            :class="{
              'active': select(item, preselect),
              'filtered': filterItem(item),
              'selected': index === cursorPosition,
            }"
            :data-value="item.id"
            @click.stop="itemSelect($event, item)"
          >
            <flag-icon :country-code="countryCode(item)">
              {{ item.name }}
            </flag-icon>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
  .city-selector {
    .ui.dropdown {
      border: none;
      padding: 0;
      border-radius: 24px;
      z-index: 10;

      @media (max-width: $mobile) {
        padding: 10px 10px 10px 15px;
        min-height: 30px;
      }

      .default.text {
        color: $cl-mako-lighten-32;
      }

      &.selection {
        padding: 0;
        border-radius: 24px;

        .menu {
          border-bottom-left-radius: 24px;
          border-bottom-right-radius: 0;
        }

        .text {
          top: -35px;
          left: 55px;
          font-size: 16px;
          color: $cl-mako-lighten-32;
        }
      }

      &.active {
        .aj-input__textField {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .worktownStep {
    .ui.dropdown.selection>.text {
      left: 14px;
      top: -32px;
      font-size: 14px;
      color: $cl-mako-lighten-32;
    }
  }

  .moreJobsView__searchLocation {
    .city-selector .ui.dropdown.selection .text {
      left: 20px;
      color: $cl-mako;
    }
  }

  #city-selector {
    width: 100%;

    :focus {
      border: 1px solid transparent;
    }
  }

  .input-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);

    @media (max-width: $mobile) {
      left: 16px;
      width: 16px;
      height: 16px;
    }

    svg {
      color: $cl-radical-red;
    }
  }

  .app {
    &.-redesignedPage {
      .ui.menu {
        &.-scrolled {
          .dropdown.item {
            color: $cl-white;
          }
        }
        .dropdown.item {
          color: $cl-mako;
        }
      }
    }
  }
</style>

<script>
import { mapState } from 'vuex';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjInput from '@appjobs/ui/src/components/AjInput/AjInput';
import MultiselectActiveItem from '@/js/components/ui/multiselect-active-item';
import FlagIcon from '@/js/components/ui/flag-icon';
import { sendGAEvent } from '@/js/helpers/analytics';
import { isMobile } from '@/js/helpers/breakpoints';
import clickMixin from '@/js/mixins/click-mixin';

const dropdownTypes = ['classic', 'inline', 'search', 'multiselect', 'simple', 'links'];

export default {
  name: 'DropdownMenu',

  components: {
    MultiselectActiveItem,
    FlagIcon,
    AjIcon,
    AjInput,
  },

  mixins: [clickMixin],

  props: {
    label: String,

    list: {
      type: Array,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    preselect: {
      type: Object,
    },

    preselectCustomValue: {
      type: Object,
      required: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    listWarning: {
      type: String,
    },

    listHeader: {
      type: String,
    },

    maxSize: {
      type: String,
    },

    type: {
      type: String,
      default: 'classic',
      validator (value) {
        const dropdownTypesString = `[${dropdownTypes.map(el => el).join(', ')}]`;

        if (dropdownTypes.indexOf(value) === -1) {
          console.error(`Dropdown type should be one of the following ${dropdownTypesString} but \'${value}\' was used instead.`);

          return false;
        }

        return true;
      },
    },

    showPlaceholder: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: '',
    },

    preventSelfToggle: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: 'search',
      required: false,
    },
  },

  data () {
    return {
      opened: false,
      searchValue: '',
      searchValueLength: 0,
      iconClass: '',
      cursorPosition: 0,
      noBoxShadow: true,
    };
  },

  computed: {
    ...mapState('i18n', ['language']),

    menuStyle () {
      return this.maxSize && {
        'max-height': `${this.maxSize}em`,
      };
    },
    isClassic () {
      return this.type === 'classic';
    },

    isMultiselect () {
      return this.type === 'multiselect';
    },

    isMultiple () {
      return this.isMultiselect ? { multiple: 'multiple' } : {};
    },

    isSearchable () {
      return this.type === 'search';
    },

    isInline () {
      return this.type === 'inline';
    },

    isLinks () {
      return this.type === 'links';
    },
    showLabel () {
      return this.label && !this.selectedItem;
    },

    nonEmptyList () {
      return this.list.length > 0;
    },

    isMobile () {
      return isMobile();
    },

    placeHolderSentence () {
      return this.showPlaceholder ? this.placeholder : '';
    },

    selectedItem: {
      cache: false,
      get () {
        if (this.isMultiselect) {
          return false;
        }

        const select = this.list.find(item => item._select === true);

        return select || this.preselect;
      },
    },

    languageCode () {
      switch (this.language) {
        case 'en':
          return 'gb';
        case 'sv':
          return 'se';
        default:
          return this.language;
      }
    },
  },

  watch: {
    opened (newValue) {
      this.$emit('open', newValue);
    },
  },

  mounted () {
    this.setDropdownOpeningIcon();
    this.setSearchItemOnLoad();
  },

  methods: {
    // From mixin, fired when user clicks outside of this.$el
    clickMixin_onOutsideComponentClick () {
      if (this.opened) {
        this.close();
      }
    },

    countryCode (item) {
      return item && item.country && item.country.countryCode
        ? item.country.countryCode.toLowerCase()
        : '';
    },

    toggle () {
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }
    },

    handleMainElementClick () {
      if (!this.preventSelfToggle) {
        this.toggle();
      }
    },

    open () {
      if (this.isSearchable) {
        if (this.name === 'city') {
          sendGAEvent({
            category: 'CitySelector',
            action: 'click',
            label: this.$route.name,
          });
        }
      }
      this.opened = true;
    },

    close () {
      this.opened = false;
    },

    moveCursor (direction = 'down') {
      if (!this.isSearchable && !this.nonEmptyList) {
        return;
      }

      if (direction === 'down' && this.cursorPosition < this.list.length - 1) {
        this.cursorPosition++;
      } else if (direction === 'up' && this.cursorPosition > 0) {
        this.cursorPosition--;
      }

      const listItem = this.list[this.cursorPosition];

      if (!listItem) {
        return;
      }

      const cursor = this.$refs.dropdown.querySelector(`[data-value="${listItem.id}"]`);
      const container = cursor.offsetParent;
      const viewport = {
        top: container.scrollTop,
        bottom: container.offsetHeight + container.scrollTop,
      };
      const cursorPos = {
        top: cursor.offsetTop,
        bottom: cursor.offsetTop + cursor.offsetHeight,
      };

      if (viewport.top > cursorPos.top) {
        container.scrollTop = cursorPos.top;
      } else if (viewport.bottom < cursorPos.bottom) {
        container.scrollTop = cursorPos.bottom - container.offsetHeight;
      }
    },

    openSelected () {
      const listItem = this.list[this.cursorPosition];

      if (this.$route.name === 'more_jobs') {
        if (listItem?.name) {
          this.searchValue = listItem?.name;
          this.itemSelect(null, listItem);
        } else {
          this.itemSelect(null, {});
        }

        return;
      }

      if (!listItem) {
        return;
      }

      if (!this.isSearchable && !this.nonEmptyList) {
        return;
      }

      if (this.name === 'city') {
        sendGAEvent({
          category: 'CitySelector',
          action: 'search',
          label: this.$route.name,
        });
      }

      this.itemSelect(null, listItem);
    },

    moveDown () {
      this.moveCursor();
    },

    moveUp () {
      this.moveCursor('up');
    },

    setDropdownOpeningIcon () {
      this.$nextTick(() => {
        if (this.isSearchable) {
          this.iconClass = 'search';
        } else if (this.$refs && this.$refs.dropdown && this.$refs.dropdown.classList.contains('upward')) {
          this.iconClass = 'angle up';
        } else if (this.isMultiselect) {
          this.iconClass = 'plus';
        }
      });
    },

    search (value) {
      if (!this.opened) {
        this.open();
      }
      this.searchValue = value;
      this.searchValueLength = value.length;
      this.$emit('search', value);

      if (this.list.length < this.cursorPosition) {
        this.cursorPosition = 0;
      }

      if (this.searchValueLength === 0) {
        this.close();
      }
    },

    clearSearchField () {
      this.$nextTick(() => {
        if (this.isSearchable && this.$refs && this.$refs.search) {
          this.$refs.search.$el.getElementsByTagName('input')[0].value = '';
          this.searchValueLength = 0;
          this.$emit('search', '');
        }
      });
    },

    filterItem (item) {
      return this.isMultiselect && this.select(item, this.preselect);
    },

    /**
       * Sets the element of the list as active and emmits list of active elements selected
       * (many/one depending on select dropdown type)
       *
       * Either event or id must be set. Event has precedence over id.
       *
       * @param event Object        E.i. mouse click event
       * @param id Integer|String   Id of the list item
       */
    itemSelect (event, listItem) {
      const activeList = [];
      const value = event ? event.currentTarget.dataset.value : String(listItem.id);
      const activate = this.list.filter(item => String(item.id) === value);
      const previouslySelected = this.list.filter(item => item._select);
      const emitFullList = this.type === 'links';
      if (this.type === 'multiselect') {
        activeList.push.apply(activeList, previouslySelected);
      }

      activeList.push.apply(activeList, activate);

      if (this.type !== 'multiselect') {
        this.close();
      }

      this.emitListOfValues(activeList, emitFullList);

      if (listItem?.name) {
        this.searchValue = listItem?.name;
      }
      if (this.$route.name === 'more_jobs' && !listItem?.name) {
        this.$emit('pushButton', listItem);
      }
    },

    itemRemove (id) {
      const activeList = this.list.filter(item => item._select);
      const toRemove = activeList.findIndex(element => element.id === id);

      activeList.splice(toRemove, 1);
      this.emitListOfValues(activeList);
    },

    emitListOfValues (activeList = [], fullList = false) {
      const values = fullList ? activeList : activeList.map(item => item);

      this.$emit('change', values);
    },

    select (item, preselect) {
      if (item.hasOwnProperty('_select') && item._select) {
        return true;
      }

      return !!(preselect && preselect.id === item.id);
    },

    setSearchItemOnLoad () {
      if (this.preselectCustomValue) {
        this.searchValue = this.preselectCustomValue.name;
      }
    },
  },
};
</script>
