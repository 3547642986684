import { post } from './request';

export default {
  postUserJobOffer (data) {
    const targetApi = data.targetApi || 'sales-api';
    const leadData = {
      offer_id: data.id,
      source: data.source,
      referral_url: data.referral_url,
      click_source: data.click_source,
      utm_source: data.utm_source,
      type: 'job',
      locale: data.locale,
    };

    if (data.email) {
      leadData.email = data.email;
    }
    if (data.full_name) {
      leadData.full_name = data.full_name;
    }
    if (data.phone) {
      leadData.phone = data.phone;
    }
    if (data.isFullTimeJob) {
      leadData.offer_id = data.offer.id;
      leadData.city = data.offer.city.slug;
      leadData.feed = data.offer.feedId;
      leadData.partner = data.offer.partner.slug;
      leadData.title = data.offer.slugTitle;
    }

    return post('/lead', {
      target: targetApi,
      data: leadData,
      silenceErrors: true,
      sendSessionId: true,
    });
  },
};
