export const redirectRoutes = [
  'more_jobs_redirect_to_partner',
  'redirect_to_partner',
];

export const routesWithRedesign = [
  'about',
  'contact',
  'terms',
  'policy',
  'home',
  'city',
  'job_offer',
  'policy',
  'partner_index',
  'partner',
  'more_jobs',
  'more_jobs_offer',
  'more_jobs_offer_canonical',
];
