const MANDATORY_VARIANTS = [1, 3, 5];
const CLOSABLE_VARIANTS = [2, 3, 5];

const mutations = {
  setVariant (stateObj, variant) {
    stateObj.variant = variant;
  },
  setMandatory (stateObj, mandatory) {
    stateObj.mandatory = mandatory;
  },
  setClosable (stateObj, closable) {
    stateObj.closable = closable;
  },
  setPartnerName (stateObj, partnerName) {
    stateObj.partnerName = partnerName;
  },
};

const actions = {
  setVariant ({ commit }, variant) {
    commit('setVariant', variant);
    commit('setMandatory', MANDATORY_VARIANTS.some(v => v === variant));
    commit('setClosable', CLOSABLE_VARIANTS.some(v => v === variant));
  },
};

const state = {
  variant: null,
  mandatory: false,
  closable: true,
  partnerName: [],
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
