import { post } from './request';

export default {
  sendPageView (data) {
    return post('/page-view', { data, sendSessionId: true });
  },

  async saveCitySearch (data) {
    return post('/city-search', { data, sendSessionId: true });
  },
};
