const mutations = {
  setJobOffer (stateObj, offer) {
    stateObj.jobOffer = offer;
  },
};

const actions = {
  setJobOffer ({ commit }, offer) {
    commit('setJobOffer', offer);
  },

  async resetJobOffer ({ commit }) {
    commit('setJobOffer', null);
  },
};

const state = {
  jobOffer: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
