<template>
  <!-- eslint-disable max-len vue/max-len -->
  <div
    class="ui dropdown inline user-menu"
    :class="{
      'active visible': opened,
    }"
    :style="[menuWidth]"
  >
    <div
      v-if="isMobile"
      class="userMenu__fullTimeButton"
    >
      <aj-button
        tag="router-link"
        :to="{ name: 'more_jobs' }"
        :title="translate('moreJobs.offer.headline')"
        primary
        small
        @click.native="fullTimeJobsClickHandler('mobile-header-button')"
      >
        {{ translate('mainMenu.fullTimeJobs') }}
      </aj-button>
    </div>
    <div
      v-if="isMobile"
      class="mobile-inital"
      @click="handleShow"
    >
      <aj-icon
        class="navigation-menu white"
        shape="navigation-menu"
      />
    </div>
    <div
      class="default text"
      @click="handleShow"
    >
      <aj-icon
        v-if="!isMobile"
        class="single-neutral-circle white"
        shape="single-neutral-circle"
      />
    </div>
    <div
      class="new menu transition"
      :class="{ 'visible': opened, 'unloggedBox': !isUser }"
      @click.self="close"
    >
      <div
        class="upper-wrapper"
        :class="{ darker: isMobile, unlogged: !isUser }"
      >
        <span
          class="mobile only closing"
          @click="close"
        >
          <span>
            {{ translate('button.close.label') }}
          </span>
          <aj-icon
            class="primary close icon black"
            shape="arrow-right-1"
          />
        </span>
        <div
          v-if="!isUser"
          class="row unlogged-user"
        >
          <aj-button
            primary
            class="ui large fluid"
            @click="handleClick('signup')"
          >
            {{ translate('auth.signUp') }}
          </aj-button>
          <aj-button
            subtle-light
            rel="nofollow"
            class="ui large fluid"
            @click="handleClick('login')"
          >
            {{ translate('auth.signIn') }}
          </aj-button>
        </div>
        <div
          v-else
          class="profile"
        >
          <div class="profile-name row">
            <div
              v-if="avatar"
              class="profile-picture"
              v-html="avatar"
            />
            <div
              v-else
              class="profile-picture"
            >
              <svg
                width="56"
                height="56"
                viewBox="0 0 24 24"
              >
                <path
                  fill="url(#gradient)"
                  d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 18.75a8.996 8.996 0 0 0-5.722 2.054A10.438 10.438 0 0 0 12 22.5c2.11 0 4.076-.623 5.722-1.695A8.99 8.99 0 0 0 12 18.75zM12 1.5C6.201 1.5 1.5 6.201 1.5 12c0 3.137 1.375 5.952 3.556 7.876a10.492 10.492 0 0 1 13.889 0A10.477 10.477 0 0 0 22.5 12c0-5.799-4.701-10.5-10.5-10.5zm0 2.25a6 6 0 1 1 0 12 6 6 0 0 1 0-12zm0 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"
                />
              </svg>
            </div>
            <div class="ui header column full-name">
              {{ displayFullName }}
              <span
                v-if="worktownMixin_worktownOrNearbyCity"
                class="sub header row city-name"
              >
                <aj-icon
                  shape="pin-1"
                  class="pin-1 row"
                />
                {{ chosenWorktown }}
              </span>
            </div>
          </div>
          <div
            v-if="isUser"
            class="about-profile row"
          >
            <aj-button
              subtle-light
              small
              @click.native="goToProfile"
            >
              {{ translate('menu.viewProfile') }}
            </aj-button>
          </div>
        </div>
      </div>
      <div
        v-if="isMobile"
        class="mobile-menu row"
      >
        <div class="column">
          <router-link
            :to="routesMixin_gigsRouteObject"
            :class="{ 'active': isActive(activeGigs) }"
            class="item row"
            @click.native="linkClick"
          >
            <aj-icon
              class="mobile-menu__icon"
              shape="task-list-approve"
            />
            <div class="column">
              <p class="ui small header">
                {{ translate('menu.gigs') }}
              </p>
              <span class="ui light sub header">
                {{ translate('menu.gigs.desc') }}
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'more_jobs' }"
            :class="{ 'active': isActive('more_jobs') }"
            class="item row"
            @click.native="fullTimeJobsClickHandler('mobile-header-menu-link')"
          >
            <aj-icon
              class="mobile-menu__icon"
              shape="description"
            />
            <div class="column">
              <p class="ui small header">
                {{ translate('mainMenu.fullTimeJobs') }}
                <new-label />
              </p>
              <span class="ui light sub header">
                {{ translate('moreJobs.offer.headline') }}
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'partner_index' }"
            :class="{ 'active': isActive('partner_index') }"
            class="item row"
            @click.native="linkClick"
          >
            <aj-icon
              class="mobile-menu__icon"
              shape="freelance-jobs"
            />
            <div class="column">
              <p class="ui small header">
                {{ translate('mainGigsTab.companies') }}
              </p>
              <span class="ui light sub header">
                {{ translate('partnerIndex.headline') }}
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'user-desk' }"
            :class="{ 'active': isActive('user-desk') }"
            class="item row"
            @click.native="linkClick"
          >
            <aj-icon
              class="mobile-menu__icon single-neutral-home"
              shape="single-neutral-home"
            />
            <div class="column">
              <p class="ui small header">
                {{ translate('mainMenu.myDesk') }}
              </p>
              <span class="ui light sub header">
                {{ translate('menu.myDesk.desc') }}
              </span>
            </div>
          </router-link>
          <a
            v-if="isUser"
            class="item menu-logout"
            href="#"
            @click.prevent="logoutClick"
          >
            <aj-icon
              shape="logout"
              class="logout"
            />{{ translate('mainMenu.logout') }}
          </a>
          <div
            class="language item"
          >
            <select-language />
          </div>
        </div>
      </div>
      <a
        v-if="isUser && !isMobile"
        class="item menu-logout"
        href="#"
        @click.prevent="logoutClick"
      >
        <aj-icon
          shape="logout"
          class="logout"
        />
        <p class="logout-text">
          {{ translate('mainMenu.logout') }}
        </p>
      </a>
    </div>
  </div>
</template>

<style lang="scss">
  .app {
    &.-redesignedPage {
      .menu {
        &:not(.-scrolled) {
          .navigation-menu,
          .single-neutral-circle {
            fill: $cl-mako;
          }
        }
      }
    }
  }
  .menu {
    .navigation-menu {
      fill: $cl-white;
    }
  }
  .mobile-menu {
    .newLabel {
      margin-left: 5px;
    }
  }
  header.ui.menu {
    .userMenu__fullTimeButton {
      a {
        margin: 0;
      }
    }
  }
</style>

<style lang="less" scoped>
  @fontColor: @lightBlack;

  .user-menu {
    .mobile-inital {
      display: flex;
      align-items: center;

      .white {
        margin-left: 20px;
      }
    }

      &.ui.ui.ui.dropdown > .new.menu {
        border: 0;
        z-index: 100;
        max-width: 100%;
        right: -10px;
        top: 40px;

        @media (min-width: @tabletBreakpoint) {

          &::before {
            position: absolute;
            content: "";
            width: 13px;
            height: 13px;
            background: @white;
            transform: rotate(45deg);
            z-index: 2;
            top: -.3em;
            right: 15px;
            bottom: auto;
            left: auto;
            margin-left: 0;
          }
        }

        &.vertical {
          padding-left: 0;
          box-shadow: -2px 0 16px 0 rgba(0, 0, 0, 0.2);
          margin-top: 20px;
          @media screen and (max-width: @largestMobileScreen) {
            margin-top: 0;
            height: 105vh;
            overflow: scroll;
          }
        }
        .upper-wrapper {
          padding: 24px 20px;
          border-bottom: 1px solid rgba(32, 32, 32, 0.1);

          &.darker {
            background-color: #f9fafc;
            width: 300px;
          }

          &.unlogged {
            padding-left: 0;
            padding-right: 0;
          }

          .ui.large.fluid {
            width: 126px;
            height: 40px;
            margin-top: 4px;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;

            &.-primary{
              margin-left: 16px;
              margin-right: 16px;
            }

            &.-subtleLight {
              margin-right: 16px;
            }
          }
          > .profile {
            display: flex;
            flex-direction: column;
            width: 300px;

            .profile-name.row {
              display: flex;
            }

            .ui.header.column {
              margin-top: 0;
            }
          }
        }

        text-align: left;
        width: 300px;
        .mobile.only.closing {
          text-align: right;
          padding-right: 24px;
          color: #495e7a;
          width: 35px;
          height: 16px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;

          span {
            position: absolute;
            right: 41px;
          }

          .close.icon {
            cursor: pointer;
            margin-right: 0;
            position: absolute;
            right: 17px;
            top: 24px;
            z-index: 15;

            &.black {
              color: @fontColor;
            }
          }
        }

        .unlogged-user {
          display: flex;
          padding-top: 32px;
          justify-content: space-around;
          align-items: baseline;

          @media screen and (min-width: @largestMobileScreen) {
            padding-top: 0;
          }
        }

        .about-profile {
          display: flex;
          justify-content: left;
          align-items: baseline;
          margin: 0;
          .ui.small.header {

            margin: 14px;
          }

          .ui.small.header:first-of-type {
            margin-left: 0;
          }
          .ui.small.header:active {
            color: #f6b026;
          }
          .sub.header {
            color: @fontColor;
          }
        }

        .item.menu-logout {
          height: 64px;
          line-height: 64px;
          color: @fontColor;
          display: inline-flex;
          padding-left: 20px !important;

          .logout-text {
            height: 18px;
            font-size: 14px;
            font-weight: bold;
            color: #222222;
          }

          .logout {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin: 20px 16px 20px 0;
          }
        }
      @media screen and (max-width: @largestMobileScreen) {
        .arrow-right-1 {
          width: 16px;
          height: 16px;
        }

        &.visible {
          position: absolute;
          top: -3px;
          display: flex !important;
          flex-direction: column;
          right: -15px;
        }

          height: 100vh;

          .mobile-menu {
            width: 300px;
            .single-neutral-home {
              width: 30px;
            }
            a.item {
              justify-content: left;
              color: @fontColor;
              margin-left: 0;
              border-bottom: 1px solid @lightGrey;
              padding: 15px 0 13px 16px;

              p.ui.small.header {
                width: 216px;
                height: 19px;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                margin-bottom: 0;
              }
              span.ui.light.sub.header {
                text-transform: inherit;
                width: 216px;
                height: 16px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
                color: #5c779a;
                white-space: initial;
              }
            }
            a.active {
              border-left: 4px solid @cl-primary-pinkish-red;
              padding-left: 12px;
              p.ui.small.header {
                color: @cl-primary-pinkish-red;
              }

              svg {
                fill: @cl-primary-pinkish-red;
              }
            }

            .language.item {

              padding: 20px 16px;
              .ui.dropdown.language-selector {
                color: @fontColor !important;
              }
              .ui.dropdown.language-selector:active {
                color: @fontColor;

                > .menu.transition.visible {
                  max-height: 50vh;
                }
              }
            }
          }

          .mobile-menu__icon {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 16px;
            fill: #9AABC2;
          }
        }
        @media screen and (max-width: 375px) {

          .about-profile {
            padding-left: 0.5em;

            .ui.small.header {
              margin: 10px;

              .sub.header {
                font-size: 12px;
              }

            }
          }

          .mobile-menu {
            a.item {
              span.ui.light.sub.header {
                font-size: 12px;
              }
            }
          }
        }
      }
      .pin-1 {
        width: 16px;
        height: 16px;
        object-fit: contain;
        margin-right: 4px;
      }

    .single-neutral-circle,
    .navigation-menu {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    .profile-picture {
      width: 56px;
      height: 56px;
      margin: 0 16px 24px 0;
    }

    .profile-avatar {
      width: 56px;
      height: 56px;
      object-fit: contain;
      fill: @cl-primary-pinkish-red;
    }

    .ui.header.column.full-name {
      text-transform: capitalize;
      height: 20px;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #232d3a;
      padding-top: 6px;
      white-space: initial;

      .sub.header.city-name {
        display: flex;
        align-items: center;
        padding-top: 8px;
        height: 24px;
        font-size: 14px;
      }
    }
  }
</style>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import NewLabel from '@/js/components/ui/new-label';
import selectLanguage from '@/js/components/ui/select-language';
import { sendGAEvent } from '@/js/helpers/analytics';
import { isMobile } from '@/js/helpers/breakpoints';
import { setComponentName, EventBus } from '@/js/helpers/utils';
import clickMixin from '@/js/mixins/click-mixin';
import profileMixin from '@/js/mixins/profile-mixin';
import routesMixin from '@/js/mixins/routes-mixin';
import worktownMixin from '@/js/mixins/worktown-mixin';

export default {
  name: 'UserMenu',

  components: {
    AjButton,
    AjIcon,
    selectLanguage,
    NewLabel,
  },

  mixins: [
    clickMixin,
    profileMixin,
    worktownMixin,
    routesMixin,
  ],

  props: {
    avatarUrl: String,
    context: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      menuWidth: {
        right: '0%',
      },
      opened: false,
      avatarImage: null,
      active: '',
    };
  },

  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    ...mapGetters(['isUser']),
    location: () => window.location,

    avatar () {
      if (this.avatarUrl) {
        return `<img class="ui big nospace avatar image" src="${this.avatarUrl}">`;
      }

      return '';
    },

    chosenWorktown () {
      return this.worktownMixin_worktownOrNearbyCity.slug ? `${this.worktownMixin_worktownOrNearbyCity.slug}, ${this.worktownMixin_worktownOrNearbyCity.country.countryCode.toUpperCase()}` : '';
    },

    displayFullName () {
      if (this.profileMixin_fullName) {
        return this.profileMixin_fullName.toLowerCase();
      }

      return this.translate('auth.greeting');
    },

    isMobile () {
      return isMobile();
    },

    activeGigs () {
      return this.$route.name === 'home' ? 'home' : 'city';
    },
  },

  watch: {
    $route (to) {
      this.active = to.name;
    },

    async profileMixin_profileData (data) {
      if (data.worktown_id) {
        this.worktownMixin_worktownOrNearbyCity = await this.worktownMixin_getWorktownById(data.worktown_id);
      }
    },
  },

  mounted () {
    this.active = this.$route.name;
  },

  methods: {
    ...mapActions('auth', ['logout', 'showAuthBox']),

    // From mixin, fired when user clicks outside of this.$el
    clickMixin_onOutsideComponentClick () {
      if (this.opened) {
        this.close();
      }
    },

    close () {
      EventBus.$emit('close-new-menu');
      setTimeout(() => {
        this.opened = false;
        this.menuWidth = { right: '0%', transition: '0s' };
      }, 0);
    },

    logoutClick () {
      this.close();
      this.logout();
    },

    goToProfile () {
      if (!this.isUser) {
        this.showAuthBox();
      }
      this.$router.push({ name: 'profile-user' });
      this.close();
    },

    linkClick () {
      this.close();
    },

    handleClick (signType) {
      setComponentName(this.$options.name);
      this.showAuthBox({ path: location.pathname, data: { initialScreen: signType, context: this.context, source: 'user-menu-tested' } });
      EventBus.$emit('close-new-menu');
      sendGAEvent({
        category: 'new-menu-signup',
        action: 'click',
        label: signType,
      });
    },

    isActive (routeName) {
      return this.active.indexOf(routeName) === 0;
    },

    handleShow () {
      if (this.opened) {
        this.opened = false;
      } else {
        this.opened = true;
        EventBus.$emit('opened-new-menu');
        if (isMobile()) {
          this.menuWidth = { right: '-2000%', transition: '0s' };
          setTimeout(() => {
            this.menuWidth = { right: '0', top: '-8px' };
          }, 0);
        }

        sendGAEvent({
          category: 'new-user-menu',
          action: 'click',
          label: isMobile ? 'mobileVariant' : 'desktop',
        });
      }
    },

    fullTimeJobsClickHandler (context) {
      this.linkClick();

      sendGAEvent({
        category: 'FullTimeJobs-menuButton',
        action: 'click',
        label: context,
      });
    },
  },
};
</script>
