import { post } from './request';

export default {
  postForgotPassword (data) {
    return post('/auth/forgot-password', { data, silenceErrors: true });
  },

  postCheckResetPasswordToken (data) {
    return post('/auth/check-reset-password-token', { data, silenceErrors: true });
  },

  postResetPassword (data) {
    return post('/auth/reset-password', { data, silenceErrors: true });
  },

  postVerifyEmailSend (data) {
    return post('/auth/verify-email-send', { data, silenceErrors: true });
  },

  postVerifyEmailConfirm (data) {
    return post('/auth/verify-email-confirm', { data, silenceErrors: true });
  },
};
