<template>
  <component
    :is="modalName"
    :modal-mixin-show="show"
    @close="hideModal"
    @confirm="confirmModal"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ModalPlaceholder',

  components: {
    AddReview: () => import('./modals/add-review-modal'),
    Auth: () => import('./modals/auth-modal'),
    ChangePassword: () => import('./modals/changePassword'),
    CookiePreferences: () => import('./modals/cookie-preferences'),
    CustomerAvailability: () => import('./modals/customer-availability'),
    DeleteProfileConfirm: () => import('./modals/deleteProfileConfirm'),
    DirectApply: () => import('../features/directApply/components/direct-apply-modal'),
    EarningsOffer: () => import('./modals/earnings-offer'),
    FavouriteCategories: () => import('./modals/favouriteCategories'),
    FiltersModal: () => import('./modals/filters-modal'),
    PartnerIndexFiltersModal: () => import('./modals/partner-index-filters-modal'),
    OfferTileReviews: () => import('./modals/offer-tile-reviews'),
    Onboarding: () => import('./modals/onboarding-modal'),
    RequestCityConfirm: () => import('./modals/requestCityConfirm'),
    ResetPassword: () => import('./modals/resetPassword'),
    ReviewDetails: () => import('./modals/reviewDetails'),
    ReviewUGC: () => import('./modals/review-ugc'),
    SetUserLanguage: () => import('./modals/setLanguages'),
    SetUserLanguageLevel: () => import('./modals/setUserLanguageLevel'),
    SetWorktown: () => import('./modals/setWorktown'),
    SignupLengthOffer: () => import('./modals/signup-length-offer'),
    UnverifiedApply: () => import('./modals/unverifiedApply'),
  },

  computed: {
    ...mapState({
      show: state => state.modal.show,
      modalName: state => state.modal.name,
    }),
  },

  watch: {
    show (newValue) {
      if (newValue) {
        document.body.classList.add('-modalOpened');
      } else {
        document.body.classList.remove('-modalOpened');
      }
    },
  },

  methods: {
    ...mapActions('modal', ['hideModal', 'confirmModal']),
  },
};
</script>
