import cityCategoryService from '@/js/services/city-category';
import seoService from '@/js/services/seo';
import reviewsService from '../../services/reviews';

const mutations = {
  setId (stateObj, id) {
    stateObj.id = id;
  },

  setCity (stateObj, city) {
    stateObj.city = city;
  },

  setCategory (stateObj, category) {
    stateObj.category = category;
  },

  setHeader (stateObj, header) {
    stateObj.header = header;
  },

  setOverview (stateObj, overview) {
    stateObj.overview = overview;
  },

  setRating (stateObj, rating) {
    stateObj.rating = rating;
  },

  setRatingCount (stateObj, ratingCount) {
    stateObj.ratingCount = ratingCount;
  },

  setReviews (stateObj, reviews) {
    stateObj.reviews = reviews;
  },

  setCityCategoryInfo (stateObj, data) {
    stateObj.cityCategory = data;
  },

  startLoading (stateObj) {
    stateObj.isLoading = true;
  },

  stopLoading (stateObj) {
    stateObj.isLoading = false;
  },
};

const actions = {
  async setData ({ commit }, cityCategory) {
    commit('setId', cityCategory.id);
    commit('setCity', cityCategory.city);
    commit('setHeader', cityCategory.header);
    commit('setCategory', cityCategory.category);
    commit('setOverview', cityCategory.overview);
    commit('setRating', cityCategory.rating);
    commit('setRatingCount', cityCategory.ratingCount);
  },

  async getReviews ({ commit }, params) {
    commit('setReviews', await reviewsService.getReviewsForCityCategory(params.categoryId, params.cityId));
  },

  async getCityCategoryInfo ({ commit }, { citySlug, categorySlug }) {
    let cityCategories = [];
    let cityCategorySeo = {};

    commit('startLoading');
    try {
      [cityCategories, cityCategorySeo] = await Promise.all([
        cityCategoryService.getInfo({
          city_slug: citySlug,
          category_slug: categorySlug,
        }),
        seoService.getCityCategorySeo({ citySlug, categorySlug }),
      ]);
    } catch (error) {
      console.error(error);
    }

    const [cityCategory] = cityCategories;
    const { seoDescription, header } = cityCategorySeo;
    commit('setCityCategoryInfo', {
      ...cityCategory,
      header: header || cityCategory.header,
      seoDescription: seoDescription || cityCategory.seoDescription,
    });
    commit('stopLoading');
  },
};

const state = {
  id: null,
  category: {},
  city: {},
  header: null,
  overview: null,
  rating: null,
  ratingCount: null,
  reviews: [],
  isLoading: false,
  cityCategory: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
