<template>
  <div class="not-found">
    <div class="ui inverted vertical masthead gradient top stripe segment">
      <container-wrapper class="center aligned big text error-content">
        <aj-icon
          shape="e404"
          class="icon404"
        />
        <p>&nbsp;</p>
        <aj-button
          tag="router-link"
          to="/"
          primary
          large
        >
          Go to AppJobs.com
        </aj-button>
      </container-wrapper>
      <container-wrapper class="fluid city dawn background" />
    </div>
  </div>
</template>

<style>
  .not-found .error-content {
    height: calc(100vh - 320px);
  }
  .not-found .icon404 {
    max-width: 326px;
    width: 80vw;
  }
</style>

<script>
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import { seoTitle } from '../helpers/seo';

export default {
  name: 'NotFound',

  components: {
    AjButton,
    AjIcon,
    ContainerWrapper,
  },

  created () {
    seoTitle('404');
  },
};
</script>
