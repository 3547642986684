<template>
  <aj-rating
    v-bind="$attrs"
    show-value
  >
    <template
      v-if="ratingCount"
      #additionalInfo
    >
      <aj-text
        class="aj-rating__additionalInfo"
        body-medium
        v-html="additionalInfoContent"
      />
    </template>
  </aj-rating>
</template>

<style lang="scss">
  .aj-rating__additionalInfo {
    margin-left: 5px;
  }
</style>

<script>
import AjRating from '@appjobs/ui/src/components/AjRating/AjRating';
import AjText from '@appjobs/ui/src/components/AjText/AjText';

export default {
  name: 'RatingInfo',

  components: {
    AjRating,
    AjText,
  },

  props: {
    ratingCount: {
      required: true,
    },
  },

  computed: {
    additionalInfoContent () {
      return `(${this.ratingCount} ${this.translate('rating.count')})`;
    },
  },
};
</script>
