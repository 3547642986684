<template>
  <div
    id="app"
    class="app"
    :class="{
      '-jobOffer': $route.name === 'job_offer',
      '-redesignedPage': isRedesignedPage,
      '-cookieOpened': !hidden,
    }"
  >
    <div
      id="vue-blind-stopper"
      class="blind-stopper"
    >
      <img
        title="Appjobs"
        alt="Appjobs"
        width="320"
        height="588"
        :src="blindStopperImageSrc"
      >
    </div>
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
  .app {
    &.-jobOffer {
      padding-bottom: 60px;
      @media (min-width: $tablet) {
        padding-bottom: 0;
      }
    }
  }
</style>

<script>
import { mapState } from 'vuex';
import {
  onCLS,
  onLCP,
  onFCP,
  onINP,
  onTTFB,
} from 'web-vitals';
import { onMounted, authListen } from '@/js/features/authorization/authorization';
import { gtag } from '@/js/helpers/analytics';
import { routesWithRedesign } from '@/js/helpers/routes';
import { Resources } from '@/js/helpers/resources';

export default {
  name: 'App',

  computed: {
    ...mapState('cookieNotice', ['hidden']),

    blindStopperImageSrc () {
      return new Resources('main-logos/logo_blindstopper_aj.webp')
        .getAssetUrl();
    },

    isRedesignedPage () {
      return routesWithRedesign.includes(this.$route.name);
    },
  },

  async mounted () {
    await onMounted();

    if (process.env.NODE_ENV === 'production') {
      this.logWebVitals();
    }
  },

  created () {
    this.hideBlindStopper();
    authListen();
  },

  methods: {
    hideBlindStopper () {
      const blindStopper = document.getElementById('original-blind-stopper');
      blindStopper.classList.add('-hidden');
    },

    logWebVitals () {
      onCLS(this.sendMetricToGoogleAnalytics);
      onLCP(this.sendMetricToGoogleAnalytics);
      onFCP(this.sendMetricToGoogleAnalytics);
      onINP(this.sendMetricToGoogleAnalytics);
      onTTFB(this.sendMetricToGoogleAnalytics);
    },

    sendMetricToGoogleAnalytics ({
      name, delta, value, id, rating,
    }) {
      gtag('event', name, {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
        metric_rating: rating,
      });
    },
  },
};
</script>
