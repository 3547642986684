import { getUtmSource } from '@/js/helpers/gap';
import { get, post, patch } from './request';

export default {
  getOffers (params = {}) {
    // add default expand parameter - city,partner
    if (typeof params.expand === 'undefined') {
      params = this.setDefaultExpand(params);
    }
    params.utm_source = getUtmSource();

    return get('/job_offers', { params }, { errorMessage: 'Cannot load job offers.' });
  },

  getOffer (params = {}) {
    params.utm_source = getUtmSource();

    return get('/job-offer', { params }, { errorMessage: 'Cannot load job offer.' });
  },

  getMyOffers () {
    return get('/my/job_offers');
  },

  postJobOfferReview (data) {
    return post(`/job_offers/${data.job_offer_id}/reviews`, {
      data,
      silenceErrors: true,
      sendSessionId: true,
    });
  },

  patchJobOfferReview (data) {
    return patch(`/job_offers/${data.job_offer_id}/reviews`, {
      data,
      silenceErrors: true,
      sendSessionId: true,
    });
  },

  setDefaultExpand (params) {
    return { ...params, expand: 'city,partner,review_partner_country' };
  },

  getReviewsByCountry (partnerId, countryCode) {
    const params = {
      country_code: countryCode,
      expand: 'city',
    };

    return get(`/reviews/partner/${partnerId}`, { silenceErrors: true, params });
  },

  getEarning (jobOfferId) {
    return get(`/job_offers/${jobOfferId}/earning`, { silenceErrors: true });
  },

  addEarning (data) {
    return post(`/job_offers/${data.jobOfferId}/earning`, {
      silenceErrors: true,
      data,
      sendSessionId: true,
    });
  },

  getSignupLengths (jobOfferId) {
    return get(`/job-offers/${jobOfferId}/sign-up-time`, { silenceErrors: true });
  },

  addSignupLength (data) {
    return post(`/job-offers/${data.jobOfferId}/sign-up-time`, {
      silenceErrors: true,
      data,
      sendSessionId: true,
    });
  },

  getOfferAvailability (jobOfferId) {
    return get(`/job-offers/${jobOfferId}/customer-availability`, { silenceErrors: true });
  },

  addOfferAvailability (data) {
    return post(`/job-offers/${data.jobOfferId}/customer-availability`, {
      silenceErrors: true,
      data,
      sendSessionId: true,
    });
  },

  getEquipment (jobOfferId) {
    return get(`/job_offers/${jobOfferId}/products/equipment`, { silenceErrors: true });
  },

  getServices (jobOfferId) {
    return get(`/job_offers/${jobOfferId}/products/service`, { silenceErrors: true });
  },

  addCost (data) {
    return post(`/job_offers/${data.jobOfferId}/cost-start`, {
      silenceErrors: true,
      data,
      sendSessionId: true,
    });
  },

  getPromotedOffers (jobOfferId, limit = 20) {
    if (!jobOfferId) {
      return [];
    }
    const params = {
      limit,
      expand: 'review_partner_country',
    };
    return get(`/job-offers/promoted/${jobOfferId}`, { params });
  },

  getMoreJobsOffers (params = {}) {
    params.utm_source = getUtmSource();

    return get(
      '/job-offers',
      {
        params,
        target: 'ofp-api',
      },
      { errorMessage: 'Cannot load job offers.' },
    );
  },

  getMoreJobsOffer (params = {}) {
    params.utm_source = getUtmSource();

    return get(
      '/job-offer',
      {
        params,
        target: 'ofp-api',
      },
      { errorMessage: 'Cannot load job offer.' },
    );
  },
};
