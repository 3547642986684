import Vue from 'vue';
import { sessionStorage } from './storage';

/**
 * This is used as a global event. Can be used anytime anywhere.
 * To invoke it you can use:
 * import { EventBus } from './helpers/utils';
 * EventBus.$emit('some-event');
 *
 * To listen to the events use:
 * EventBus.$on('some-event', callback);
 * Also available methods: $once, $off
 * Do not overuse this bus as global events makes code less readable.
 * However sometimes it is very necessary.
 */
export const EventBus = new Vue();

/**
 * Used to be called as last invocation. The tryout is made within wait time.
 * If immediate is set then debounced function is called
 * immediately and never again within wait time.
 * @param func A function to call
 * @param wait A wait time to check whether to call the function
 * @param immediate If the function should be called in the beginning or at the end
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

/**
 * This determines how often the throttled function should be called
 *
 * @param func A function to throttle
 * @param limit A time limit (frequency)
 * @returns {Function}
 */
export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;

  return function () {
    const args = arguments;
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan > limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export function decodeRot13 (str) {
  return str.replace(/[A-Z]/gi, c => 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm'[
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.indexOf(c)]);
}

export const parseToXml = text => {
  if (window.DOMParser) {
    const parser = new DOMParser();

    return parser.parseFromString(text, 'text/xml');
  }
  if (window.ActiveXObject) {
    // Internet Explorer
    const xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM');
    xmlDoc.async = false;

    return xmlDoc.loadXML(text);
  }

  return undefined;
};

export function setComponentName (name, context, routeName) {
  const routeNames = {
    'profile-employers': 'profile-reviews',
    'profile-user': 'profile',
  };
  if (context === 'profile') {
    name = routeNames[routeName];
  }

  sessionStorage[name ? 'setItem' : 'removeItem'](
    'componentName',
    name,
  );
}

export const removeEmptyParagraphs = html => html
  .replace(/<p>\s+<\/p>/gm, '');
