'use strict';

const cognitoBase = {
  mandatorySignIn: false,
  region: 'eu-west-1',
  userPoolId: 'eu-west-1_MF1X8cgwV',
  userPoolWebClientId: '1519o2ab4vtqqff8ohvj6lgm3k',

  federated: {
    facebook_client_id: process.env.VUE_APP_FACEBOOK_APP_ID,
    google_client_id: '197448299729-51gsbf4vf11etkhhsvajvii4daordefq.apps.googleusercontent.com',
  },

  oauth: {
    domain: 'auth.appjobs.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignOut: window.location.origin,
    redirectSignIn: window.location.origin,
    responseType: 'code',
  },

  // Example: https://aws-amplify.github.io/docs/js/vue#signup-field-attributes
  authConfig: {
    usernameAttributes: 'email',
    signUpConfig: {
      federated: {
        facebook_client_id: process.env.VUE_APP_FACEBOOK_APP_ID,
        google_client_id: '197448299729-51gsbf4vf11etkhhsvajvii4daordefq.apps.googleusercontent.com',
      },
      hideAllDefaults: true,
      signUpFields: [
        {
          label: 'Email',
          key: 'email',
          required: true,
          displayOrder: 1,
          type: 'email',
          signUpWith: true,
        },
        {
          label: 'Password',
          key: 'password',
          required: true,
          type: 'password',
          displayOrder: 2,
        },
      ],
    },
  },
};

export const cognito = cognitoBase;

export const base = {
  clientID: '1519o2ab4vtqqff8ohvj6lgm3k',
  domain:   'auth.appjobs.com',
};

export const google = {
  apiKey: 'AIzaSyDtk0ObWV1LxY_3fKHo26EAvXOLLOj2RnI',
};

export default {
  cognito,
  google,
  base
};
