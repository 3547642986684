import queryString from 'query-string';
import { del, get, post } from './request';

export default {
  getLatestReviews (limit = 1, params = {}) {
    let paramsStr = queryString.stringify(params);

    if (paramsStr) {
      paramsStr = `&${paramsStr}`;
    }

    return get(`/reviews/latest?limit=${limit}&expand=city,partner${paramsStr}`);
  },

  getTopReviews () {
    return get('/reviews/top?expand=city,partner', { authorize: false, silenceErrors: true });
  },

  getReviewsForJobOffer (jobOffer, limit = 1) {
    return get(`/reviews/latest?job_offer_id=${jobOffer}&limit=${limit}`);
  },

  getLatestForCity (cityId) {
    return this.getLatestReviews(3, { city_id: cityId });
  },

  getReviewsForCityCategory (categoryId, cityId) {
    return this.getLatestReviews(3, { city_id: cityId, category_id: categoryId });
  },

  getReviewsByPartner (partnerId) {
    const params = {
      expand: 'city',
    };

    return get(`/reviews/partner/${partnerId}`, { silenceErrors: true, params });
  },

  rememberDismissedReview (jobOfferId) {
    return post('/reviews/opportunity/dismiss', {
      data: { job_offer_id: jobOfferId },
    });
  },

  async postReviewOpportunity (jobOfferId) {
    return post('/reviews/opportunity', {
      data: { job_offer_id: jobOfferId },
    });
  },

  deleteReviewOpportunity (jobOfferId) {
    return del(`/reviews/opportunity/${jobOfferId}`);
  },
};
