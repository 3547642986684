export default {
  name: 'blog',
  path: 'blog',
  component: () => import('@/js/views/Blog/blog-root'),

  redirect: () => ({ name: 'blog-index' }),

  children: [
    {
      name: 'blog-index',
      path: '',
      component: () => import('@/js/views/Blog/blog-view'),
    },
    {
      name: 'blog-post',
      path: ':postSlug(\[a-zA-Z0-9-]+\)',
      component: () => import('@/js/views/Blog/blog-post'),
    },
  ],
};
