<template>
  <div>
    <aj-loader
      :show="isPageLoading"
      class="componentLoader"
    />
    <more-jobs-view />
  </div>
</template>

<script>
import store from '@/js/store';
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { translate } from '@/js/helpers/i18n';
import { seoTitle } from '@/js/helpers/seo';
import { breakDownUsCityName } from '@/js/helpers/str';
import { EventBus } from '@/js/helpers/utils';

const searchQueryObject = {};

async function processFilters (route) {
  await processFilteredCity(route);
  await processFilteredState(route);
  await processFilteredCountryCode(route);
  await processfilteredKeyword(route);
}

async function processFilteredCity (route) {
  const filteredCity = route.query.city
    || breakDownUsCityName(store.state.cities?.nearbyCities[0]?.name).name;
  if (filteredCity) {
    await store.dispatch('moreJobs/setFilteredCity', filteredCity);
  } else {
    await store.dispatch('moreJobs/clearFilteredCity');
  }
  searchQueryObject.city = filteredCity || undefined;
}

async function processFilteredState (route) {
  const filteredState = route.query.state
    || breakDownUsCityName(store.state.cities?.nearbyCities[0]?.name).state;
  if (filteredState) {
    await store.dispatch('moreJobs/setFilteredState', filteredState);
  } else {
    await store.dispatch('moreJobs/clearFilteredState');
  }
  searchQueryObject.state = filteredState || undefined;
}

async function processFilteredCountryCode (route) {
  const filteredCountryCode = route.query.country_code
    || store.state.cities?.nearbyCities[0]?.country?.countryCode.toUpperCase();
  if (filteredCountryCode) {
    await store.dispatch('moreJobs/setFilteredCountryCode', filteredCountryCode);
  } else {
    await store.dispatch('moreJobs/clearFilteredCountryCode');
  }
  searchQueryObject.country_code = filteredCountryCode;
}

async function processfilteredKeyword (route) {
  const filteredKeyword = route.query.keyword;
  if (filteredKeyword) {
    await store.dispatch('moreJobs/setFilteredKeyword', filteredKeyword);
  } else {
    await store.dispatch('moreJobs/clearFilteredKeyword');
  }
}

function setSeoTitle ({ page }) {
  let title = translate('seo.title.moreJobsView');
  page = page ? parseInt(page.split('page-')[1]) : 1;

  if (page > 1) {
    title += ` - ${translate('pagination.page', { number: page })}`;
  }

  seoTitle(title);
}

export default {
  name: 'MoreJobsRoute',

  components: {
    AjLoader,
    MoreJobsView: () => import('@/js/views/MoreJobs/more-jobs-view'),
  },

  data () {
    return {
      isPageLoading: true,
    };
  },

  created () {
    EventBus.$on('more-jobs-view-loaded', () => {
      this.isPageLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    const routeParams = to.params;

    if (store.state.cities?.nearbyCities?.length === 0) {
      await store.dispatch('cities/getNearbyCities', 4);
    }

    await processFilters(to);
    setSeoTitle(routeParams);

    next(vm => {
      vm.$router.push({
        params: routeParams,
        query: searchQueryObject,
      });
    });
  },

  async beforeRouteUpdate (to, from, next) {
    setSeoTitle(to.params);

    next();
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
