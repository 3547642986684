import Vue from 'vue';
import router from 'router';
import { v4 as uuidv4 } from 'uuid';
import Auth from '@aws-amplify/auth';
import { AUTH } from '@/js/enums/modal';
import { sentryCaptureException } from '@/js/helpers/sentry';
import { sessionStorage } from '@/js/helpers/storage';
import profileService from '@/js/services/profile';
import userService from '@/js/services/user';

function parseIndentityProvider (profile) {
  if (profile['custom:appjobs_user_id']) {
    const appjobsIdSource = profile['custom:appjobs_user_id'].split('|')[0];
    if (appjobsIdSource.includes('google')) {
      return 'Google';
    }
    if (appjobsIdSource.includes('facebook')) {
      return 'Facebook';
    }

    return 'COGNITO';
  }

  return '';
}

const mutations = {
  setAccessToken (stateObj, accessToken) {
    stateObj.accessToken = accessToken;
  },

  setIdToken (stateObj, idToken) {
    stateObj.idToken = idToken;
  },

  setEmailConsent (stateObj, emailConsent) {
    stateObj.emailConsent = emailConsent;
  },

  setIsReadyToSaveUser (stateObj, isReadyToSaveUser) {
    stateObj.isReadyToSaveUser = isReadyToSaveUser;
  },

  setProfile (stateObj, profile) {
    stateObj.profile = profile;
  },

  setUserMetadata (stateObj, metadata) {
    // TODO: Remove here nesting data, metadata should be separate state object
    Vue.set(stateObj.profile, 'user_metadata', metadata);
  },

  setRedirectPath (stateObj, redirectPath) {
    stateObj.redirectPath = redirectPath;
  },

  expiresAt (stateObj, expiresAt) {
    stateObj.expiresAt = expiresAt;
  },

  updateLastRenewedAt (stateObj) {
    stateObj.lastRenewedAt = Date.now();
  },

  setStep (stateObj, stepName) {
    stateObj.stepName = stepName;
  },

  setRegisterSource (stateObj, registerSource) {
    stateObj.registerSource = registerSource;
  },

  setVerificationCode (stateObj, code) {
    stateObj.verificationCode = code;
  },

  setIndentityProvider (stateObj, provider) {
    stateObj.identityProvider = provider;
  },

  setPostProcess (stateObj, shouldPostProcess) {
    stateObj.shouldPostProcess = shouldPostProcess;
  },

  setIsProcessing (stateObj, isProcessing) {
    stateObj.isProcessing = isProcessing;
  },

  setAuthTrigger (stateObj, authTrigger) {
    stateObj.authTrigger = authTrigger;
  },

  setAuthErrorMessage (stateObj, authErrorMessage) {
    stateObj.authErrorMessage = authErrorMessage;
  },
};

const actions = {
  async login ({ commit, dispatch, state }, {
    accessToken, idToken, profile, expiresAt,
  }) {
    commit('setAccessToken', accessToken);
    commit('setIdToken', idToken);
    commit('setProfile', profile);
    commit('setIndentityProvider', parseIndentityProvider(profile));
    commit('expiresAt', expiresAt);
    commit('updateLastRenewedAt');
    if (state.authTrigger === 'signIn') {
      await dispatch('getUserMetadata');
    }
  },

  beforeRenewLogin ({ commit }) {
    commit('updateLastRenewedAt');
  },

  renewLogin ({ commit }, { accessToken, idToken }) {
    commit('setAccessToken', accessToken);
    commit('setIdToken', idToken);
    commit('updateLastRenewedAt');
  },

  async logout ({ commit, rootState, dispatch }, redirectToAfterLogout) {
    commit('setAccessToken', null);
    commit('setIdToken', null);
    commit('setProfile', null);
    commit('setEmailConsent', false);
    await dispatch('resetAuthTrigger');
    let langSuffix = rootState.i18n.language;
    if (langSuffix === 'en') {
      langSuffix = '';
    }

    try {
      await Auth.signOut();
    } catch (_) {
      sentryCaptureException(_);
    } finally {
      window.location = `${window.location.origin}/${langSuffix}`;
    }
    if (redirectToAfterLogout) {
      const params = { ...redirectToAfterLogout.params, language: rootState.i18n.language };

      router.push({ ...redirectToAfterLogout, params });
    }
  },

  setRedirectPath ({ commit }, redirectPath) {
    commit('setRedirectPath', redirectPath);
  },

  setEmailConsent ({ commit }, emailConsent) {
    commit('setEmailConsent', emailConsent);
  },

  setIsReadyToSaveUser ({ commit }, isReadyToSaveUser) {
    commit('setIsReadyToSaveUser', isReadyToSaveUser);
  },

  async patchUserMetadata ({ commit, state }, { userId, data }) {
    commit('setUserMetadata', await profileService.setUserMetadata({
      userId,
      email: state.profile.email,
      ...data,
    }));
  },

  async updateProfile ({ commit, state }, { userId, data }) {
    commit('setUserMetadata', await profileService.syncUserData({
      userId,
      email: state.profile.email,
      ...data,
    }));
  },

  async getUserMetadata ({ commit }) {
    commit('setUserMetadata', await profileService.getUserMetadata());
  },

  async deleteUser () {
    await userService.deleteUser();
  },

  showAuthBox ({ dispatch, commit }, { path, data }) {
    dispatch(
      'modal/showModal',
      {
        name: AUTH,
        data: { initialScreen: 'signup', ...data },
      },
      { root: true },
    );

    if (data.source) {
      commit('setRegisterSource', data.source);
    }

    dispatch('setRedirectPath', path);
  },

  nextStep ({ commit }, stepName) {
    commit('setStep', stepName);
  },

  resetStep ({ commit }) {
    commit('setStep', '');
  },

  resetRegisterSource ({ commit }) {
    commit('setRegisterSource', '');
  },

  initSessionId () {
    const key = 'session-id';
    let sessionId = sessionStorage.getItem(key);
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem(key, sessionId);
    }

    return sessionId;
  },

  enablePostProcess ({ commit, dispatch }, authTrigger) {
    commit('setPostProcess', true);
    dispatch('setAuthTrigger', authTrigger);
  },

  disablePostProcess ({ commit }) {
    commit('setPostProcess', false);
  },

  startProcessing ({ commit }) {
    commit('setIsProcessing', true);
  },

  stopProcessing ({ commit }) {
    commit('setIsProcessing', false);
  },

  setAuthTrigger ({ commit }, authTrigger) {
    commit('setAuthTrigger', authTrigger);
  },
  resetAuthTrigger ({ commit }) {
    commit('setAuthTrigger', null);
  },
};

const state = {
  accessToken: null,
  idToken: null,
  profile: null,
  // url that user is redirected to after login/signup
  redirectPath: '/',
  // format: ms since the unix epoch
  expiresAt: null,
  // format: ms since the unix epoch
  lastRenewedAt: null,
  // for checking what registration step to display (e.g. popup with first and last name)
  stepName: '',
  registerSource: '',
  verificationCode: '',
  identityProvider: '',
  shouldPostProcess: false,
  isProcessing: false,
  emailConsent: false,
  isReadyToSaveUser: false,
  authTrigger: null,
  authErrorMessage: '',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  parseIndentityProvider,
};
