<template>
  <div class="newLabel">
    <aj-text
      tag="span"
      body-low
      bold
    >
      NEW
    </aj-text>
  </div>
</template>

<style lang="scss" scoped>
  .newLabel {
    display: inline-block;
    padding: 1px 5px;
    transform: skew(-10deg);
    background-color: $cl-radical-red;
    color: $cl-white;

    .aj-text {
      display: inline-block;
      transform: skew(10deg);
    }
  }
</style>

<script>
import AjText from '@appjobs/ui/src/components/AjText/AjText';

export default {
  name: 'NewLabel',

  components: {
    AjText,
  },
};
</script>
