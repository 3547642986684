import userLangsService from '../../services/user-language';

const mutations = {
  startLoadingUserLanguages (stateObj) {
    stateObj.isLoadingUserLanguages = true;
  },
  stopLoadingUserLanguages (stateObj) {
    stateObj.isLoadingUserLanguages = false;
  },
  setLangsList (stateObj, langs) {
    stateObj.languagesList = langs;
  },
  setUserLangs (stateObj, langs) {
    stateObj.userLanguagesList = langs;
  },
  addToUpdateList (stateObj, lang) {
    stateObj.userLanguagesToUpdate.push(lang);
  },
  addToDeleteList (stateObj, langCode) {
    stateObj.userLanguagesToDelete.push(langCode);
  },
  removeFromDeleteList (stateObj, langCode) {
    stateObj.userLanguagesToDelete.splice(stateObj.userLanguagesToDelete.indexOf(langCode), 1);
  },
  removeFromUpdateList (stateObj, langCode) {
    stateObj.userLanguagesToUpdate
      .splice(stateObj.userLanguagesToUpdate.findIndex(lang => lang.id === langCode), 1);
  },
  saveLanguageListChanges (stateObj) {
    stateObj.userLanguagesList = stateObj.userLanguagesList
      .filter(
        lang => !stateObj.userLanguagesToDelete.find(langCode => langCode === lang.id),
      ).concat(
        stateObj.userLanguagesToUpdate,
      );
  },
  clearUserChanges (stateObj) {
    stateObj.userLanguagesToDelete = [];
    stateObj.userLanguagesToUpdate = [];
  },
};

const actions = {
  async getLanguages ({ commit }) {
    commit('startLoadingUserLanguages');
    commit('setLangsList', await userLangsService.getLangs());
    commit('setUserLangs', await userLangsService.getUserLangs());
    commit('stopLoadingUserLanguages');
  },

  addUserLanguageToUpdate ({ commit, state }, lang) {
    const addListIndex = state.userLanguagesToUpdate.findIndex(userLang => lang.id === userLang.id);
    const deleteListIndex = state.userLanguagesToDelete
      .findIndex(userLangCode => lang.id === userLangCode);
    const mainListIndex = state.userLanguagesList.findIndex(userLang => userLang.id === lang.id);
    if (addListIndex < 0) {
      commit('addToUpdateList', lang);
    } else {
      commit('removeFromUpdateList', lang.id);
    }
    if (deleteListIndex > -1 && mainListIndex < 0) {
      commit('removeFromDeleteList', lang.id);
    }
  },

  deleteUserLanguage ({ commit, state }, lang) {
    const addListIndex = state.userLanguagesToUpdate.findIndex(userLang => lang.id === userLang.id);
    const deleteListIndex = state.userLanguagesToDelete.findIndex(userLang => lang.id === userLang);
    const mainListIndex = state.userLanguagesList.findIndex(userLang => userLang.id === lang.id);
    if (addListIndex > -1) {
      commit('removeFromUpdateList', lang.id);
    }
    if (mainListIndex > -1 && deleteListIndex < 0) {
      commit('addToDeleteList', lang.id);
    }
  },

  async saveUserLanguageChanges ({ commit, state }) {
    if (state.userLanguagesToDelete.length
      || state.userLanguagesToUpdate.length) {
      try {
        await userLangsService.updateUserLangs({
          add_languages: state.userLanguagesToUpdate,
          delete_languages: state.userLanguagesToDelete.map(id => ({ id })),
        });
        commit('saveLanguageListChanges');
        commit('clearUserChanges');
      } catch (_) {
        // silent error
      }
    }
  },
};

const getters = {
  hasUserLangs (stateObj) {
    return stateObj.userLanguagesList.length > 0;
  },

  userChosenLanguages (stateObj) {
    return stateObj.userLanguagesList
      .filter(
        lang => stateObj.userLanguagesToDelete.indexOf(lang.id) < 0,
      ).concat(
        stateObj.userLanguagesToUpdate,
      )
      .map(
        lang => ({
          ...stateObj.languagesList.find(l => l.id === lang.id),
          ...lang,
        }),
      );
  },
  // eslint-disable-next-line no-shadow
  userLanguagesToPick (stateObj, getters) {
    return stateObj.languagesList.filter(
      lang => getters.userChosenLanguages.findIndex(userLang => lang.id === userLang.id) === -1,
    );
  },
};

const state = {
  languagesList: [],
  userLanguagesList: [],
  userLanguagesToUpdate: [],
  userLanguagesToDelete: [],
  isLoadingUserLanguages: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
