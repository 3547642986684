<template>
  <div>
    <aj-loader
      :show="isPartnerLoading"
      class="componentLoader"
    />
    <partner-view />
  </div>
</template>

<script>
import store from '@/js/store';
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { seoTitle } from '@/js/helpers/seo';
import { translate } from '@/js/helpers/i18n';
import { getCityNameFromCitiesListByCitySlug } from '@/js/helpers/cities';
import { getCountryCodeBySlug, getCountryNameBySlug } from '@/js/helpers/countries';
import { EventBus } from '@/js/helpers/utils';

async function fetchData (params) {
  await store.dispatch('partner/getPartner', {
    partnerSlug: params.partnerSlug,
  });
  if (store.state.partner.partnerCities.length) {
    return;
  }

  if (params.countrySlug) {
    await store.dispatch('partner/getPartnerCities', {
      partnerId: store.state.partner.partner.id,
      countryCode: getCountryCodeBySlug(
        store.state.partner.partner.countries,
        params.countrySlug,
      ),
    });
  }
}

function resolveSeoTitleTranslation (params) {
  if (params.countrySlug && params.citySlug) {
    return 'seo.title.partnerView.withCountryAndCity';
  }

  return 'seo.title.partnerView';
}

function setSeoTitle (pathParams, partnerObject) {
  const translationObject = {
    partnerName: partnerObject.name,
  };

  if (pathParams.citySlug) {
    translationObject.cityName = getCityNameFromCitiesListByCitySlug(
      store.state.partner.partnerCities,
      pathParams.citySlug,
    );
  }

  if (pathParams.countrySlug) {
    translationObject.countryName = getCountryNameBySlug(
      partnerObject.countries,
      pathParams.countrySlug,
    );
  }
  seoTitle(translate(resolveSeoTitleTranslation(pathParams), translationObject));
}

export default {
  name: 'PartnerRoute',

  data () {
    return {
      isPartnerLoading: true,
    };
  },

  components: {
    PartnerView: () => import('@/js/views/Partner/partner-view'),
    AjLoader,
  },

  async beforeRouteEnter (to, from, next) {
    await fetchData(to.params);
    const { partner } = store.state.partner;
    if (!partner.id) {
      next({
        name: 'not_found',
      });
    } else {
      setSeoTitle(to.params, partner);

      next();
    }
  },

  async beforeRouteUpdate (to, from, next) {
    await fetchData(to.params);
    const { partner } = store.state.partner;

    if (to.path === from.path) {
      return;
    }

    setSeoTitle(to.params, partner);

    next();
  },

  created () {
    EventBus.$on('partner-loaded', () => {
      this.isPartnerLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
