/*
  This kind of hack allows us to easily mock functions which are used in scope of same module,
  without that it's really problematic or even impossible to do that
  more information regarding that problem can be found here:
  https://newbedev.com/how-to-mock-functions-in-the-same-module-using-jest
 */
/* eslint import/no-self-import: "off" */
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import ms from 'ms';
import { afterLoginProcessing } from '@/js/features/authorization/auth-postprocessing';
import { sentryCaptureException } from '@/js/helpers/sentry';
import { storage } from '@/js/helpers/storage';
import { checkEmailVerification } from '@/js/features/emailVerification/emailVerification';
import store from '@/js/store';
import * as thisModule from './authorization';

export async function onMounted () {
  try {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    thisModule.loginUser(user);
  } catch (e) {
    console.log(e);
    if (e !== 'The user is not authenticated') {
      throw e;
    }
  }
}

export function authListen () {
  Hub.listen('auth', thisModule.authCallback);
}

export async function authCallback ({ payload: { event, data } }) {
  const userPath = storage.getItem('previousPath');
  storage.removeItem('previousPath');
  switch (event) {
    case 'signIn':
      if (userPath) {
        window.location.replace(userPath);
      }
      if (data.signInUserSession) {
        await thisModule.loginUser(data);
      } else {
        await Auth.currentSession();
        await thisModule.loginUser(await Auth.currentAuthenticatedUser({
          bypassCache: true,
        }));
      }
      break;
    case 'signUp_failure':
    case 'signIn_failure':
      console.error(data);
      break;
    default:
      break;
  }
}

export async function loginUser (userData) {
  store.dispatch('auth/startProcessing');
  userData = userData || await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  try {
    await checkEmailVerification(userData);
  } catch (_) {
    sentryCaptureException(_);
  }
  const user = thisModule.buildUserObject(userData);
  await store.dispatch('auth/login', user);
  await afterLoginProcessing(user);
  store.dispatch('auth/stopProcessing');
}

export function buildUserObject (user) {
  return {
    idToken: user.signInUserSession.idToken.jwtToken,
    accessToken: user.signInUserSession.accessToken.jwtToken,
    expiresAt: ms(`${user.signInUserSession.idToken.payload.exp}s`),
    profile: {
      ...user.attributes,
      user_id: user.attributes['custom:appjobs_user_id'],
    },
  };
}
