import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import router from 'router';
import Amplify from '@aws-amplify/core';
import { EventBus, throttle } from '@/js/helpers/utils';
import vulnerabilities from '@/js/helpers/vulnerabilities';
import App from './App';
import './plugins/translate';
import store from './store';
/* eslint-disable-next-line */
import 'main-styles';
import { cognito } from '../../config/auth';
import '@/styles/_styles.scss';

const getSentry = () => import('@/js/plugins/sentry');

let ticking = false;
let lastKnownScrollPosition = 0;

Vue.use(VueLazyload, {
  listenEvents: ['scroll', 'resize'],
  lazyComponent: true,
  observer: true,

  observerOptions: {
    rootMargin: '0px',
    threshold: 0.5,
  },
});

Amplify.configure({
  Auth: cognito,
});

new Vue({
  el: '#app',
  router,
  store,

  mounted () {
    getSentry();
    window.addEventListener('scroll', throttle(this.onScrollTicker, 100));
  },

  destroyed () {
    window.removeEventListener('scroll', throttle(this.onScrollTicker, 100));
  },

  methods: {
    onScrollTicker () {
      lastKnownScrollPosition = window.pageYOffset;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          EventBus.$emit('body-scrolled', lastKnownScrollPosition);
          ticking = false;
        });
        ticking = true;
      }
    },
  },
  render (h) {
    return h(App);
  },
});

Vue.mixin({
  computed: {
    isDev () {
      return process.env.NODE_ENV === 'development';
    },
    isStage () {
      return process.env.VUE_APP_STAGE;
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  vulnerabilities.dynamicLinksNoopenerVulnerabilityCheck();
}
