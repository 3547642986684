<template>
  <a
    class="ui middle aligned label transition visible"
    data-value="dataValue"
  >
    <slot />
    <aj-icon
      shape="times"
      class="icon"
      @click.native="removeItem"
    />
  </a>
</template>

<script>
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';

export default {
  name: 'MultiselectActiveItem',

  components: {
    AjIcon,
  },

  props: {
    dataValue: {
      type: Number,
      required: true,
    },
  },

  methods: {
    removeItem () {
      this.$emit('remove', this.dataValue);
    },
  },
};
</script>
