import { mapActions, mapGetters, mapState } from 'vuex';
import profileMixin from '@/js/mixins/profile-mixin';
import citiesService from '@/js/services/cities';

export default {
  data () {
    return {
      worktownMixin_worktownOrNearbyCity: {},
      worktownMixin_worktown: {},
    };
  },

  mixins: [profileMixin],

  computed: {
    ...mapState('cities', ['cities', 'nearbyCities']),
    ...mapGetters('cities', ['hasNearbyCities', 'hasCities']),

    worktownMixin_hasWorktownOrNearbyCity () {
      return this.worktownMixin_worktownOrNearbyCity && this.worktownMixin_worktownOrNearbyCity.name;
    },
  },

  watch: {
    nearbyCities () {
      this.worktownMixin_setWorktownForVisitor();
    },

    profile (profile) {
      this.$nextTick(() => {
        if (profile && profile.user_metadata) {
          this.worktownMixin_setWorktownForUser();
        }
      });
    },
  },

  created () {
    this.worktownMixin_setWorktownForUser();
    this.worktownMixin_setWorktownForVisitor();
  },

  methods: {
    ...mapActions('cities', ['getCities']),

    // ##### User related methods

    worktownMixin_setWorktownForUser () {
      if (!this.isUser || !this.profileMixin_worktownId) {
        return;
      }

      this.worktownMixin_setWorktownOrNearbyCityForUser();
    },

    async worktownMixin_setWorktownOrNearbyCityForUser () {
      if (this.worktownMixin_hasWorktownOrNearbyCity) {
        return;
      }

      this.worktownMixin_worktownOrNearbyCity = await this.worktownMixin_getWorktownById(parseInt(this.profileMixin_worktownId));
      this.worktown = this.worktownMixin_worktownOrNearbyCity;
    },

    // ##### Visitor related methods

    worktownMixin_setWorktownForVisitor () {
      if (this.isUser) {
        return;
      }

      this.worktownMixin_setWorktownOrNearbyCityForVisitor();
    },

    worktownMixin_setWorktownOrNearbyCityForVisitor () {
      if (!this.hasNearbyCities || this.worktownMixin_hasWorktownOrNearbyCity) {
        return;
      }

      this.worktownMixin_worktownOrNearbyCity = this.nearbyCities[0];
      this.worktown = {};
    },

    async worktownMixin_getWorktownById (worktownId) {
      if (this.cities.length) {
        return this.cities.find(city => city.id === parseInt(worktownId)) || null;
      }

      const fetchedCity = await citiesService.getCity({ cityId: parseInt(worktownId) });
      return fetchedCity;
    },

    worktownMixin_getWorktownNameById (worktownId) {
      if (!this.cities.length) {
        return null;
      }
      return this.cities.find(city => city.id === parseInt(worktownId)).name || null;
    },
  },
};
