const mutations = {
  setRecaptchaSourceElement (state, element) {
    state.recaptchaSourceElement = element;
  },
};

const actions = {
  resetRecaptchaSourceElement ({ commit }) {
    commit('setRecaptchaSourceElement', null);
  },
};

const state = {
  recaptchaSourceElement: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
