<template>
  <bubble-icon
    class="category"
    :shape="slug"
    :name="name"
    :size="size"
    :background="background"
  />
</template>

<script>
import BubbleIcon from './bubble-icon';

export default {
  name: 'CategoryIcon',

  components: {
    BubbleIcon,
  },

  props: {
    name: {
      type: String,
    },

    size: {
      type: String,
    },

    slug: {
      type: String,
      required: true,
    },

    background: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
