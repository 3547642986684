import Cookies from 'js-cookie';
import { sentryCaptureException } from '@/js/helpers/sentry';
import { jobOfferToProduct, sendGATransaction } from '@/js/helpers/analytics';
import { isFullTimeJobOffer } from '@/js/helpers/jobOffer';
import { getSourceData, getUtmSource } from '@/js/helpers/gap';
import userJobOffersService from './user-job-offers';
import reviewsService from './reviews';
import store from '../store';

export async function postLeadId (data) {
  if (store.getters.isUser) {
    try {
      await reviewsService.postReviewOpportunity(data.id);
    } catch (_) {
      sentryCaptureException(_);
    }
  }
  return userJobOffersService.postUserJobOffer(data);
}

export function postJobOfferLead ({
  offer,
  clickData,
  targetApi,
}) {
  if (clickData) {
    if (clickData.context) {
      offer.context = clickData.context;
    }
    if (clickData.position) {
      offer.position = clickData.position;
    }
    if (clickData.clickSource) {
      offer.clickSource = clickData.clickSource;
    }
    if (clickData.buttonType) {
      offer.buttonType = clickData.buttonType;
    }
  }
  const clickSource = offer.clickSource ? `${offer.clickSource}_page` : 'directBufferPage';

  const product = jobOfferToProduct(offer);
  const transactionObj = {
    affiliation: product.item_name,
    revenue: product.price,
    products: [product],
  };

  if (product.item_list_id) {
    transactionObj.list = product.item_list_id;
  }

  const data = {
    id: offer.id,
    source: getSourceData(),
    referral_url: store.getters.referralUrl,
    locale: store.state.i18n.language,
    click_source: clickSource,
    utm_source: getUtmSource(),
    targetApi,
  };

  if (offer.isDirectApply) {
    let email;
    let firstName;
    let lastName;
    let phone;
    if (store.getters.isUser) {
      email = store.state.auth?.profile?.user_metadata?.email;
      firstName = store.state.auth?.profile?.user_metadata?.given_name;
      lastName = store.state.auth?.profile?.user_metadata?.family_name;
      phone = store.state.auth?.profile?.user_metadata?.phone;
    } else {
      email = store.getters.directApplyGuestData?.email;
      firstName = store.getters.directApplyGuestData?.given_name;
      lastName = store.getters.directApplyGuestData?.family_name;
      phone = store.getters.directApplyGuestData?.phone;
    }

    data.email = email;
    data.full_name = [
      firstName,
      lastName,
    ].filter(Boolean).join(' ');
    data.phone = phone;
  }

  if (isFullTimeJobOffer(offer)) {
    data.offer = offer;
    data.isFullTimeJob = true;
  }

  return postLeadId(data)
    .then(response => {
      transactionObj.id = response.data.id;

      if (response.data.userAnonymousId) {
        Cookies.set('userId', response.data.userAnonymousId);
      }

      return response.data.created
        ? sendGATransaction(transactionObj).then(() => response.data)
        : response.data;
    })
    .catch(() => {
      sendGATransaction(transactionObj);
    });
}
