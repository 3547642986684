export class Resources {
  constructor (asset) {
    this.asset = asset;
    this.comaSeparated = [];
    this.transformations = [];
  }

  getAssetUrl (type = 'image') {
    const transformations = [];

    if (this.comaSeparated.length) {
      transformations.push(this.comaSeparated.join(','));
    }

    if (this.transformations.length) {
      transformations.push(this.transformations.join('/'));
    }

    const transformationsString = transformations.length ? `${transformations.join('/')}/` : '';

    return `//res.cloudinary.com/hhgz8qnrm/${type}/upload/f_auto/${transformationsString}${this.asset}`;
  }

  width (width) {
    this.comaSeparated.push(`w_${width}`);

    return this;
  }

  height (height) {
    this.comaSeparated.push(`h_${height}`);

    return this;
  }

  /**
   * Prepares string for cloudinary crop method
   *
   * @param crop Strin Available values:
   * scale, fit, mfit, fill, lfill, limit, pad, lpad, mpad, crop, thumb, imagga_crop, imagga_scale
   */
  crop (crop) {
    const available = [
      'scale',
      'fit',
      'mfit',
      'fill',
      'lfill',
      'limit',
      'pad',
      'lpad',
      'mpad',
      'crop',
      'thumb',
      'imagga_crop',
      'imagga_scale',
    ];

    if (!available.some(a => a === crop)) {
      throw new Error('Improper crop method');
    }

    this.comaSeparated.push(`c_${crop}`);

    return this;
  }

  /**
   * Sets image quality
   *
   * @param quality String|Number
   * Available values: 'best', 'good', 'eco', 'low' or number between 1..100
   */
  quality (quality) {
    const available = ['best', 'good', 'eco', 'low'];

    if (!(available.some(a => a === quality)
      || (!isNaN(quality) && quality >= 1 && quality <= 100))) {
      throw new Error('Improper quality modifier');
    }

    if (isNaN(quality)) {
      this.transformations.push(`q_auto:${quality}`);
    } else {
      this.transformations.push(`q_${quality}`);
    }

    return this;
  }

  /**
   * Uses some image to multiply its colors against dark or light solid color set in Cloudinary
   *
   * @param variant String    Available values: 'dark', 'light'
   */
  multiply (variant) {
    const available = ['dark', 'light'];

    if (!available.some(a => a === variant)) {
      throw new Error('Improper multiplication variant');
    }

    this.transformations.push(`l_background-${variant},e_multiply,fl_tiled`);

    return this;
  }

  grayscale () {
    this.transformations.push('e_grayscale');

    return this;
  }

  gradientY (value, color) {
    this.transformations.push(`e_gradient_fade,y_${value},b_rgb:${color}`);

    return this;
  }
}

export function getAssetUrlForCategoryHeader (fileName) {
  if (!fileName) {
    return '';
  }

  return new Resources(`categories/headers/${fileName}.jpg`).quality(50).getAssetUrl();
}

export function getPartnerSquareLogoUrl (partnerName) {
  if (!partnerName) {
    return '';
  }

  return new Resources(`partners/argyle_logos/${partnerName}.png`)
    .width(50)
    .height(50)
    .quality('eco')
    .getAssetUrl();
}

export async function loadExternalScript (url) {
  return new Promise(resolve => {
    if (document.getElementById(url)) {
      resolve(); // was already loaded
    }

    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.id = url;
    document.getElementsByTagName('head')[0].appendChild(scriptTag);
    scriptTag.onload = resolve;
  });
}
