import cityCategoryService from '../../services/city-category';

const mutations = {
  startLoadingCityCategories (stateObj) {
    stateObj.isLoadingCityCategories = true;
  },

  stopLoadingCityCategories (stateObj) {
    stateObj.isLoadingCityCategories = false;
  },

  setCityCategories (stateObj, categories) {
    stateObj.cityCategories = categories;
  },

  startLoadingCardCategories (stateObj) {
    stateObj.isLoadingCardCategories = true;
  },

  stopLoadingCardCategories (stateObj) {
    stateObj.isLoadingCardCategories = false;
  },

  setCardsCategories (stateObj, categories) {
    stateObj.cardsCategories = categories;
  },
};

const actions = {
  async getCityCategories ({ commit, state }, citySlug) {
    if (state.isLoadingCityCategories) {
      return;
    }

    commit('startLoadingCityCategories');
    try {
      commit('setCityCategories', await cityCategoryService.getCityCategoriesByCity(citySlug));
    } catch (e) {
      commit('setCityCategories', []);
    }
    commit('stopLoadingCityCategories');
  },

  async getCardsCategories ({ commit, state }, params) {
    commit('startLoadingCardCategories');
    commit('setCityCategories', await cityCategoryService.getCityCategoriesByCity(params.citySlug, 'partners_count'));

    const cardsCategories = [];
    state.cityCategories
      .filter(cityCategory => cityCategory.category.slug !== params.categorySlug)
      .slice(0, 4)
      .forEach(cityCategory => {
        cardsCategories.push({ ...cityCategory.category, header: cityCategory.header });
      });

    commit('setCardsCategories', cardsCategories);
    commit('stopLoadingCardCategories');
  },
};

const state = {
  cityCategories: [],
  cardsCategories: [{}, {}, {}, {}],
  isLoadingCityCategories: false,
  isLoadingCardCategories: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
