import YoutubeIcon from '../../sprite/youtube.svg';

class VideoOnDemand {
  constructor (element) {
    this.videosIframes = element.tagName === 'IFRAME' ? [element] : Array.from(element.getElementsByTagName('iframe'));
  }

  reduceIframesAmount () {
    if (this.videosIframes.length > 2) {
      this.videosIframes.length -= 1;
    }
  }

  prepareVideosAndAnchors () {
    this.videos = [];
    this.anchors = [];

    this.videosIframes.forEach((video, index) => {
      const videoElement = video.parentNode.parentNode;
      const keyMatch = video.src.match(/embed\/(.*)\?/);

      // stop proceeding if YT key not found
      if (!(keyMatch && keyMatch[1])) {
        return;
      }

      const videoObj = {
        key: keyMatch[1],
        src: video.src,
        element: videoElement,
      };

      // put wrapper after video iframe
      const anchor = videoElement.parentNode.insertBefore(document.createElement('div'), videoElement.nextSibling);
      anchor.setAttribute('class', 'vod');
      anchor.setAttribute('data-id', index);
      // remove video iframe from dom
      setTimeout(() => {
        if (videoElement.parentElement) {
          videoElement.parentElement.removeChild(videoElement);
        }
      }, 100);

      // save data for further processing
      this.anchors[index] = anchor;
      this.videos[index] = videoObj;
    });
  }

  static createPlayIcon () {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('width', '80');
    svg.setAttribute('height', '60');
    svg.setAttribute('viewBox', YoutubeIcon.viewBox);
    svg.setAttribute('class', 'vod-svg');

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', 'M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z');
    path.setAttribute('class', 'vod-path');
    svg.appendChild(path);

    return svg;
  }

  loadThumbnail (anchor, resolution = 'maxresdefault') {
    const video = this.videos[anchor.getAttribute('data-id')];
    const src = `https://i.ytimg.com/vi/${video.key}/${resolution}.jpg`;
    const img = new Image();

    img.src = src;
    img.onload = event => {
      const path = event.path || (event.composedPath && event.composedPath());
      // fallback if max resolution thumbnail doesn't exits
      // width 120 means that yt returned default image for missing thumbnail
      if (path && path[0].width === 120) {
        this.loadThumbnail(anchor, 'hqdefault');

        return;
      }

      // put image inside anchor
      anchor.appendChild(img);

      // create play icon
      const svg = VideoOnDemand.createPlayIcon();
      anchor.appendChild(svg);

      // add event listener for anchor container
      anchor.addEventListener('click', () => {
        anchor.removeChild(img);

        // update iframe src to autoplay video
        const iframe = video.element.getElementsByTagName('iframe')[0];
        iframe.setAttribute('allow', 'autoplay');
        iframe.setAttribute('src', `${video.src}&autoplay=1&enablejsapi=1`);

        // display video player with autoplay
        anchor.appendChild(video.element);
      });
    };
  }

  loadThumbnails () {
    this.anchors.forEach(anchor => {
      this.loadThumbnail(anchor);
    });
  }

  createObserver () {
    const options = {
      root: null,
      threshold: 0,
    };
    const intersectionObj = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        this.loadThumbnail(entry.target);
        observer.unobserve(entry.target);
      });
    }, options);
    this.anchors.forEach(anchor => {
      intersectionObj.observe(anchor);
    });
  }

  bind () {
    if (!this.videosIframes.length) {
      return;
    }

    this.reduceIframesAmount();
    this.prepareVideosAndAnchors();

    if (!window.IntersectionObserver) {
      this.loadThumbnails();
    } else {
      this.createObserver();
    }
  }
}

export default {
  bind: el => {
    new VideoOnDemand(el).bind();
  },
};
