<template>
  <div>
    <aj-loader
      :show="!phrases && !messages.length"
      full-size
    />
    <template v-if="phrases">
      <div
        class="dimmable root"
        :class="{ 'dimmed': isModalActive }"
        :style="`margin-top: ${pageOffset}px`"
      >
        <top-bar
          ref="topBar"
          @heightChange="updatePageOffest"
        />
        <template v-if="messages">
          <div
            ref="flashMessage"
            class="flashMessage"
          >
            <aj-message
              v-for="message in messages"
              :key="message.id"
              :visible="visible"
              :type="message.variation"
              :closable="message.closable"
              :title="message.title"
              :message="message.message"
              :action-label="message.actionLabel"
              @click:action="message.actionHandler(message.id)"
              @click:close="closeFlashMessage(message.id, message.closeHandler)"
            />
          </div>
        </template>
        <menu-header
          ref="menuHeader"
          :offset-top="pageOffset"
        />
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <router-view />
        </transition>
        <app-footer />
      </div>
      <modal-placeholder />
      <cookie-notice />
    </template>
    <template v-else>
      <aj-message
        v-for="message in messages"
        :key="message.id"
        :visible="visible"
        :type="message.variation"
        :closable="message.closable"
        :title="message.title"
        :message="message.message"
        :action-label="message.actionLabel"
        @click:action="message.actionHandler(message.id)"
        @click:close="closeFlashMessage(message.id, message.closeHandler)"
      />
    </template>
    <svg
      class="root__linearGradient"
      style="width:0;height:0"
      aria-hidden="true"
      focusable="false"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="100%"
          x2="0%"
          y1="99.979%"
          y2=".021%"
        >
          <stop
            offset="0%"
            stop-color="#F6B026"
          />
          <stop
            offset="100%"
            stop-color="#F78A04"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
  .root > .loader {
    min-height: 100vh;
  }
</style>

<style lang="scss">
  .root__linearGradient {
    position: absolute;
  }
  .flashMessage {
    position: fixed;
    z-index: 10000;
    width: 100%;
    bottom: 0;
    @media screen and (min-width: 769px) {
      top: 0;
      bottom: auto;
    }
  }
</style>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import Cookies from 'js-cookie';
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import AjMessage from '@appjobs/ui/src/components/AjMessage/AjMessage';
import { isMobile } from '@/js/helpers/breakpoints';
import { sendGAEvent } from '@/js/helpers/analytics';
import TopBar from '@/js/components/top-bar';
import appFooter from '@/js/components/app-footer';
import menuHeader from '@/js/components/menu-header';
import ModalPlaceholder from '@/js/components/modal-placeholder';
import CookieNotice from '@/js/components/cookie-notice';
import { setSourceData } from '@/js/helpers/gap';
import { seoTitle } from '@/js/helpers/seo';
import { storage } from '@/js/helpers/storage';
import { setComponentName } from '@/js/helpers/utils';
import { AUTH } from '@/js/enums/modal';
import profileMixin from '@/js/mixins/profile-mixin';

export default {
  name: 'RootView',

  components: {
    TopBar,
    appFooter,
    menuHeader,
    ModalPlaceholder,
    CookieNotice,
    AjLoader,
    AjMessage,
  },

  mixins: [profileMixin],

  async beforeRouteUpdate (to, from, next) {
    seoTitle();
    next();
  },

  data () {
    return {
      scrolling: 0,
      vector: 1,
      rocketFuelUrl: null,
      visible: true,
      storageKeys: {
        age: {
          save: 'profile_age_to_save',
        },
        earnings: 'user_earnings_for_offer',
        signUpLength: 'signup_length_for_offer',
        customerAvailability: 'customer_availability',
        lowOnCost: 'low_on_cost_for_offer',
      },
      flashMessageHeight: 0,
      pageOffset: 0,
    };
  },

  computed: {
    ...mapState({
      messages: state => state.messages,
      phrases: state => state.i18n.phrases,
      isModalActive: state => state.modal.show,
      modalData: state => state.modal.data,
      modalName: state => state.modal.name,
      authBox: state => state.authBox,
    }),
    ...mapState('auth', ['stepName', 'profile']),
    ...mapState(['city']),
    ...mapState('i18n', ['language']),
    ...mapState('cities', ['nearbyCities', 'cities']),
    ...mapGetters(['isUser', 'isPushableMessage']),

    isTermsAndConditionsPage () {
      return this.$route.name === 'terms';
    },

    isMessage () {
      return this.messages;
    },

    isMobile () {
      return isMobile();
    },
  },

  watch: {
    nearbyCities (nearbyCities) {
      if (!this.city.slug && nearbyCities.length > 0) {
        this.setCity(nearbyCities[0]);
      }
    },

    isTermsAndConditionsPage (itIs) {
      if (itIs && this.modalName === AUTH) {
        this.hideModal();
      }
      this.invokeAuthBox();
    },

    isMessage (messages) {
      if (messages) {
        this.visible = true;
        this.$nextTick(() => {
          this.updateFlashMessageHeight();
          this.updatePageOffest();
        });
      }
    },

    phrases (data) {
      if (Object.keys(data).length !== 0) {
        this.$nextTick(() => {
          this.updateFlashMessageHeight();
          this.updatePageOffest();
        });
      }
    },
    $route () {
      this.updatePageOffest();
    },
  },

  created () {
    setSourceData(this.$route.query);
    const expirationDate = Date.now() + 60 * 24 * 3600000;

    if (
      !this.isUser
      && this.$route.query.referral_id
      && !Cookies.get('referralData')
    ) {
      Cookies.set(
        'referralData',
        JSON.stringify({
          url: location.href,
          expires_at: expirationDate,
        }),
        { expires: 60 },
      );
    }

    document.documentElement.lang = this.language;

    // local storage data handle
    this.handleClickThroughData();
  },

  mounted () {
    this.hideBlindStopper();
    this.invokeAuthBox();
  },

  methods: {
    ...mapActions(['setMessage']),
    ...mapActions('auth', ['showAuthBox', 'updateProfile']),
    ...mapActions('modal', ['showModal', 'hideModal']),
    ...mapActions('authBox', ['setVariant']),
    ...mapActions('cities', ['getCities', 'getNearbyCities']),
    ...mapActions('city', ['setCity']),
    ...mapActions('jobOffer', ['addEarning', 'addSignupLength', 'addCustomerAvailability', 'addCost']),
    ...mapActions(['deleteMessage']),

    setInitialScreen () {
      return this.$route.query.vt && this.$route.query.vt === '5' ? 'login' : 'signup';
    },

    invokeAuthBox () {
      if (this.isUser) {
        return;
      }

      if (!this.authBox.variant && this.$route.query.vt) {
        this.setVariant(parseInt(this.$route.query.vt));
      }

      if (this.authBox.mandatory && !this.isTermsAndConditionsPage) {
        setComponentName(this.$options.name);
        this.showAuthBox({
          path: this.$route.path,
          data: {
            initialScreen: this.setInitialScreen(),
            unclosable: !this.authBox.closable,
            source: 'Root-Invoke',
          },
        });
      }
    },

    handleClickThroughData () {
      if (!this.isUser) {
        return;
      }

      this.updateAgeData();
      this.updateEarningsData();
      this.updateSignupUpLengthData();
      this.updateCustomerAvailabilityData();
      this.updateLowOnCostData();
    },

    updateAgeData () {
      const ageToSave = storage.getItem(this.storageKeys.age.save);

      if (!ageToSave) {
        return;
      }

      this.updateProfile({
        userId: this.profile.user_id,
        data: {
          birthdate: ageToSave,
        },
      }).then(() => {
        storage.removeItem(this.storageKeys.age.save);
        sendGAEvent({
          category: 'ProfileAge',
          action: 'saved',
          label: 'userclickthrough',
        });
      });
    },

    updateEarningsData () {
      const earnings = storage.getItem(this.storageKeys.earnings);

      if (!earnings) {
        return;
      }
      const { jobOfferId, value } = JSON.parse(earnings);
      this.addEarning({
        jobOfferId,
        value,
      }).then(() => {
        storage.removeItem(this.storageKeys.earnings);
        sendGAEvent({
          category: 'earningsOffer',
          action: this.profileMixin_isNewUser ? 'userclickthrough' : 'userclick',
          label: 'UGC',
        });
      });
    },

    updateSignupUpLengthData () {
      const signupUpLength = storage.getItem(this.storageKeys.signUpLength);

      if (!signupUpLength) {
        return;
      }

      const { jobOfferId, value } = JSON.parse(signupUpLength);
      this.addSignupLength({
        jobOfferId,
        value,
      }).then(() => {
        storage.removeItem(this.storageKeys.signUpLength);
        sendGAEvent({
          category: 'signupLengthOffer',
          action: this.profileMixin_isNewUser ? 'userclickthrough' : 'userclick',
          label: 'UGC',
        });
      });
    },

    updateCustomerAvailabilityData () {
      const customerAvailability = storage.getItem(this.storageKeys.customerAvailability);

      if (!customerAvailability) {
        return;
      }

      const { jobOfferId, value } = JSON.parse(customerAvailability);
      this.addCustomerAvailability({
        jobOfferId,
        value,
      }).then(() => {
        storage.removeItem(this.storageKeys.customerAvailability);
        sendGAEvent({
          category: 'customerAvailability',
          action: this.profileMixin_isNewUser ? 'userclickthrough' : 'userclick',
          label: 'UGC',
        });
      });
    },

    updateLowOnCostData () {
      const lowOnCost = storage.getItem(this.storageKeys.lowOnCost);

      if (!lowOnCost) {
        return;
      }

      const { jobOfferId, value } = JSON.parse(lowOnCost);
      this.addCost({
        jobOfferId,
        value,
      }).then(() => {
        storage.removeItem(this.storageKeys.lowOnCost);
        sendGAEvent({
          category: 'lowOnCostOffer',
          action: this.profileMixin_isNewUser ? 'userclickthrough' : 'userclick',
          label: 'UGC',
        });
      });
    },

    updateFlashMessageHeight () {
      if (this.$refs.flashMessage) {
        this.flashMessageHeight = (this.isPushableMessage && !this.isMobile)
          ? this.$refs.flashMessage.clientHeight
          : 0;
      }
    },

    updatePageOffest () {
      if (this.$refs.menuHeader) {
        const topBarHeight = this.$refs.topBar.$el.clientHeight || 0;
        this.pageOffset = (this.flashMessageHeight > topBarHeight)
          ? this.flashMessageHeight : topBarHeight;
      }
    },

    closeFlashMessage (id, closeHandler) {
      this.deleteMessage(id);
      this.visible = false;

      if (closeHandler && typeof closeHandler === 'function') {
        closeHandler();
      }
    },

    hideBlindStopper () {
      const blindStopper = document.getElementById('vue-blind-stopper');
      blindStopper.classList.add('-hidden');
    },
  },
};
</script>
