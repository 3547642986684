/**
 * Handles clicks within vue component
 * and fires `clickMixin_onOutsideComponentClick` method
 * when click appears to be outside this.$el
 */
export default {
  methods: {
    clickMixin_clickHandler (event) {
      if (!this.$el.contains(event.target) && this.$el !== event.target) {
        this.clickMixin_onOutsideComponentClick(event);
      }
    },
  },
  mounted () {
    // Wait for event loop to empty before attaching the event
    // to not fire it on some pending clicks
    setTimeout(() => {
      document.body.addEventListener('click', this.clickMixin_clickHandler);
    });
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.clickMixin_clickHandler);
  },
};
