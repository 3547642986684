<template>
  <span>
    <i
      v-if="countryCode"
      :class="countryCode"
      class="flag"
    />
    <slot />
  </span>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: { countryCode: { type: String, default: '' } },
};
</script>
