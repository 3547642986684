<template>
  <div
    v-if="reviews.length || forceTitleShow"
    class="ui vertical not separated stripe small segment dark beige"
  >
    <container-wrapper
      id="reviews"
      class="left aligned"
      :class="{ ['narrow text']: reviews.length <= 3 }"
    >
      <container-wrapper class="center aligned">
        <div class="ui center aligned alternative header">
          <slot name="header">
            {{ translate('jobOffer.reviews') }}
          </slot>
        </div>
      </container-wrapper>
      <div
        class="ui review doubling stackable cards"
        :class="{ three: reviews.length <= 3, four: reviews.length > 3 }"
      >
        <review-item
          v-for="review in reviews"
          :key="review.id"
          :review="review"
        />
      </div>
    </container-wrapper>
  </div>
</template>

<style lang="scss" scoped>
  .separated.segment.beige {
    background: $cl-dawn-pink-lighten-8;
  }

  .ui.review.cards {
    font-size: 0.8em;
  }

  .with-rating .ui.header.alternative {
    border-bottom: none;
  }

  .ui.alternative.header {
    line-height: 1.5;
    padding: 0;
  }
</style>

<script>
import { mapActions } from 'vuex';
import ReviewItem from '@/js/components/review-item';
import ContainerWrapper from '@/js/components/ui/container-wrapper';

export default {
  name: 'ReviewsSegment',

  components: {
    ReviewItem,
    ContainerWrapper,
  },

  props: {
    reviews: { type: Array, required: true },
    forceTitleShow: { type: Boolean, default: false },
  },

  watch: {
    reviews () {
      this.hasReviews(this.reviews.length > 0);
    },
  },

  mounted () {
    this.$nextTick(() => {
      if (this.$route.query.show && this.$route.query.show === 'reviews') {
        if (this.reviews.length) {
          this.$scrollTo('#reviews', 1000, { offset: 0 });
        } else {
          this.$scrollTo('.footer.segment', 1000, { offset: -100 });
        }
      }
    });
  },

  methods: {
    ...mapActions('jobOffer', ['hasReviews']),
  },
};
</script>
