/**
 * This is rather very specific function to build params object basing on string.
 * Example:
 *    { someParam: 'qwe.asd.zxc' }
 * will return
 *    { someParam: obj.qwe.asd.zxc }
 *
 * @param params Object Incoming object with values as strings.
 * @param obj Object Target object (fe. state) to set the string value as its real attributes.
 * @returns Object
 */
const buildParams = (params, obj) => {
  if (params && params.translatable) {
    return {};
  }

  const paramsToGo = {};
  const paramsArray = Object.entries(params);

  paramsArray.forEach(param => {
    const key = param[0];
    const objPath = param[1];
    paramsToGo[key] = objPath.split('.').reduce((o, i) => o[i], obj);
  });

  return paramsToGo;
};

export default buildParams;
