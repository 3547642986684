import { NOT_LOADED_IMAGE_ERROR } from '@/js/enums/errors';

const getSentry = () => import('@sentry/browser');
class NotLoadedImageError extends Error {
  constructor (message) {
    super(message);
    this.name = NOT_LOADED_IMAGE_ERROR;
  }
}

function sendNotLoadedImageErrorMessage (Sentry, error, imageSrc) {
  Sentry.withScope(scope => {
    scope.setLevel('warning');
    scope.setTag('image.src', imageSrc);
    Sentry.captureException(error);
  });
}

export default {
  bind: el => {
    const images = el.tagName === 'IMG' ? [el] : Array.from(el.getElementsByTagName('img'));
    images.forEach(img => {
      img.setAttribute('data-src', img.src);
      img.removeAttribute('src');
    });
  },

  inserted: el => {
    const images = el.tagName === 'IMG' ? [el] : Array.from(el.getElementsByTagName('img'));

    function loadImages () {
      images.forEach(image => {
        image.src = image.dataset.src;
      });
    }

    function loadImage (image) {
      image.addEventListener('load', () => {
        setTimeout(() => el.classList.add('loaded'), 100);
      });
      image.addEventListener('error', () => {
        getSentry().then(sentryModule => {
          sendNotLoadedImageErrorMessage(
            sentryModule,
            new NotLoadedImageError('Image was not loaded and returned 404 error'),
            image.dataset.src,
          );
        });
      });
      image.src = image.dataset.src;
    }

    function handleIntersect (entries, observer) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        loadImage(entry.target);
        observer.unobserve(entry.target);
      });
    }

    function createObserver () {
      const options = {
        root: null,
        threshold: 0,
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      images.forEach(image => {
        observer.observe(image);
      });
    }

    if (!images.length) {
      return;
    }

    // check if observer exist
    if (!window.IntersectionObserver) {
      loadImages();
    } else {
      createObserver();
    }
  },
};
