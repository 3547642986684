<template>
  <div>
    <flag-icon :country-code="languageCode" />
    <select
      class="language-selector"
      @change="redirectLanguage($event)"
    >
      <option
        v-for="item in languageList"
        :key="item.id"
        :value="item.uri"
        :selected="select(item)"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<style lang="less" scoped>
  .language-selector {
    height: 19px;
    margin-left: 16px;
    padding-left: 0.85em;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232d3a;
  }
</style>

<script>
import { mapState } from 'vuex';
import FlagIcon from '@/js/components/ui/flag-icon';
import { sendGAEvent } from '../../helpers/analytics';

export default {
  name: 'SelectLanguage',

  components: {
    FlagIcon,
  },

  computed: {
    ...mapState('i18n', ['language']),
    ...mapState({
      languageList: state => state.i18n.list,
      currentLanguage: state => state.i18n.language,
    }),

    languageCode () {
      switch (this.language) {
        case 'en':
          return 'gb';
        case 'sv':
          return 'se';
        default:
          return this.language;
      }
    },
  },
  methods: {
    select (item) {
      if (item.hasOwnProperty('_select') && item._select) {
        return true;
      }

      return undefined;
    },

    redirectLanguage (event) {
      location.href = event.target.value;

      sendGAEvent({
        category: 'selected-language',
        action: 'click',
        label: this.language,
      });
    },
  },

};
</script>
