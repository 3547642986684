import { translate } from '@/js/helpers/i18n';

export default {
  build ({
    city,
    cityCategory,
    partner,
    partnerIndex,
    partnerView,
    partnerViewCountry,
    partnerViewCity,
    moreJobsView,
    moreJobsCityView,
    moreJobsOfferView,
    moreJobsOfferExpired,
  }) {
    const breadcrumbsArr = [];

    if (city) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.city', { cityName: city.name }),
        to: cityCategory ? { name: 'city', params: { citySlug: city.slug } } : null,
      });
    }

    if (cityCategory) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.cityCategory', { categoryName: cityCategory.name, cityName: city.name }),
        to: partner ? { name: 'city_category', params: { citySlug: city.slug, categorySlug: cityCategory.slug } } : null,
      });
    }

    if (partner) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.jobOffer', { partnerName: partner.name, cityName: city.name }),
      });
    }

    if (partnerIndex) {
      breadcrumbsArr.push({
        title: translate('mainGigsTab.companies'),
        to: partnerView ? { name: 'partner_index' } : null,
      });
    }

    if (partnerView) {
      breadcrumbsArr.push({
        title: translate('seo.title.partnerView', { partnerName: partnerView.name }),
        to: partnerViewCountry ? { name: 'partner', params: { partnerSlug: partnerView.slug } } : null,
      });
    }

    if (partnerViewCountry) {
      breadcrumbsArr.push({
        title: partnerViewCountry.name,
        to: partnerViewCity ? { name: 'partner', params: { partnerSlug: partnerView.slug, countrySlug: partnerViewCountry.slug } } : null,
      });
    }

    if (partnerViewCity) {
      breadcrumbsArr.push({
        title: partnerViewCity.name,
      });
    }

    if (moreJobsView) {
      breadcrumbsArr.push({
        title: translate('footer.moreJobs'),
        to: moreJobsOfferView || moreJobsOfferExpired ? { name: 'more_jobs' } : null,
      });
    }

    if (moreJobsCityView) {
      const cityText = [moreJobsCityView.cityName, moreJobsCityView?.state].filter(Boolean).join(', ');
      const queryObject = {
        city: moreJobsCityView.cityName,
        country_code: moreJobsCityView.countryCode,
      };
      if (moreJobsCityView.state) {
        queryObject.state = moreJobsCityView.state;
      }

      breadcrumbsArr.push({
        title: translate('jobOffer.more_jobs.city', { city: cityText }),
        to: moreJobsOfferView || moreJobsOfferExpired ? {
          name: 'more_jobs',
          query: queryObject,
        } : null,
      });
    }

    if (moreJobsOfferView) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.jobOffer', {
          partnerName: moreJobsOfferView.partnerName,
          cityName: moreJobsOfferView.cityName,
        }),
      });
    }

    if (moreJobsOfferExpired) {
      breadcrumbsArr.push({
        title: translate('breadcrumbs.jobOffer.expired'),
      });
    }

    return breadcrumbsArr;
  },
};
