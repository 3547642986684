<template>
  <header class="ui secondary pointing menu">
    <router-link
      :to="routesMixin_gigsRouteObject"
      :class="{ 'active': isActive(activeGigs) }"
      class="item ui"
    >
      <span> {{ translate('menu.gigs') }} </span>
    </router-link>
    <router-link
      :to="{ name: 'more_jobs' }"
      :class="{ 'active': isActive('more_jobs') }"
      class="item"
      @click.native="fullTimeJobsClickHandler"
    >
      {{ translate('mainMenu.fullTimeJobs') }}
      <new-label />
    </router-link>
    <router-link
      :to="{ name: 'partner_index' }"
      :class="{ 'active': isActive('partner_index') }"
      class="item"
    >
      {{ translate('mainGigsTab.companies') }}
    </router-link>
    <router-link
      :to="{ name: 'user-desk' }"
      :class="{ 'active': isActive('user-desk') }"
      class="item"
    >
      {{ translate('mainMenu.myDesk') }}
    </router-link>
  </header>
</template>

<style lang="scss" scoped>
  .ui.secondary.pointing.menu {
    .item {
      margin-bottom: 0;
      height: 100%;
      margin-right: 20px;
      color: $cl-white;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.2px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $cl-radical-red;
      }

      &.active {
        border-color: $cl-radical-red;

        &:hover {
          border-color: $cl-radical-red;
        }
      }
    }

    .newLabel {
      margin-left: 10px;
    }
  }
</style>

<script>
import NewLabel from '@/js/components/ui/new-label';
import { sendGAEvent } from '@/js/helpers/analytics';
import profileMixin from '@/js/mixins/profile-mixin';
import routesMixin from '@/js/mixins/routes-mixin';

export default {
  name: 'MenuInlineHeader',

  components: {
    NewLabel,
  },

  mixins: [profileMixin, routesMixin],

  data () {
    return {
      active: '',
    };
  },

  computed: {
    activeGigs () {
      return this.$route.name === 'home' ? 'home' : 'city';
    },
  },

  watch: {
    $route (to) {
      this.active = to.name;
    },
  },

  mounted () {
    this.active = this.$route.name;
  },

  methods: {
    isActive (routeName) {
      return this.active.indexOf(routeName) === 0;
    },

    fullTimeJobsClickHandler () {
      sendGAEvent({
        category: 'FullTimeJobs-menuButton',
        action: 'click',
        label: 'desktop-header-link',
      });
    },
  },
};
</script>
