import Auth from '@aws-amplify/auth';
import store from '@/js/store';
import { get, patch } from './request';

export default {
  getUserMetadata () {
    return get('/my/user/metadata/', {
      authorize: true,
      errorMessage: 'Cannot load my metadata.',
    });
  },

  setUserMetadata (data) {
    return patch('/my/user/metadata/', {
      authorize: true,
      data,
    });
  },

  async updateCognitoUserName (metaData) {
    if (metaData.family_name || metaData.given_name) {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      await Auth.updateUserAttributes(user, {
        given_name: metaData.given_name || '',
        family_name: metaData.family_name || '',
      });
    }
  },

  async syncUserData (data) {
    let metaData;
    if (store.state.auth.profile.user_metadata
      && Object.keys(store.state.auth.profile.user_metadata).length !== 0) {
      metaData = store.state.auth.profile.user_metadata;
    } else {
      metaData = await this.getUserMetadata() || {};
    }
    metaData = { ...metaData, ...data };

    this.updateCognitoUserName(metaData);

    return this.setUserMetadata(metaData);
  },
};
