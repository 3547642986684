/*
  This kind of hack allows us to easily mock functions which are used in scope of same module,
  without that it's really problematic or even impossible to do that
  more information regarding that problem can be found here:
  https://newbedev.com/how-to-mock-functions-in-the-same-module-using-jest
 */
/* eslint import/no-self-import: "off" */
import { storage } from '@/js/helpers/storage';
import { transformKeysToLowercase } from '@/js/helpers/str';
import * as thisModule from './gap';

/**
 * Determines whether to set the session source based on the given query.
 *
 * @param {Object} query - The query object with transformed keys.
 * @returns {boolean} True if the session source should be set, otherwise false.
 */
function shouldSetSessionSource (query) {
  return !thisModule.getSourceData()
    || query.utm_source
    || query.utm_medium
    || query.utm_campaign
    || query.rx_p
    || query.subid
    || query.click_id
    || query.pid
    || query.jg_clickid
    || query.jr_cid
    || query.gclid;
}

/**
 * Builds the session source string based on the given query.
 *
 * @param {Object} query - The query object with transformed keys.
 * @returns {string} The session source string.
 */
function buildSessionSourceString (query) {
  const {
    utm_source = '',
    utm_medium = '',
    utm_campaign = '',
    gclid = false,
    subid = '',
    rx_p = '',
    click_id = '',
    pid = '',
    jg_clickid = '',
    jr_cid = '',
  } = query;

  const sourceSubid = subid || rx_p || click_id || pid || jg_clickid || jr_cid;
  const gclidPresent = gclid ? 'true' : 'false';

  return `${document.referrer || 'unknown'}|source=${utm_source}|medium=${utm_medium}|campaign=${utm_campaign}|gclid=${gclidPresent}|source_subid=${sourceSubid}`;
}

/**
 * Sets the session source data based on the given query object.
 * Transforms the keys to lowercase and refreshes the session before setting the data.
 *
 * @param {Object} queryObject - The original query object.
 */
export function setSourceData (queryObject) {
  const query = transformKeysToLowercase(queryObject);
  thisModule.refreshSession();

  if (shouldSetSessionSource(query)) {
    const sessionSourceString = buildSessionSourceString(query);
    storage.setItem('sessionSource', sessionSourceString);
  }
}

/**
 * Retrieves the session source data from storage if it has not expired.
 *
 * @returns {string|null} The session source data if available and not expired, or null otherwise.
 */
export function getSourceData () {
  if (storage.getItem('sessionSourceExpiresAt') > Date.now()) {
    return storage.getItem('sessionSource');
  }

  return null;
}

export function getUtmSource () {
  const CHAR_LIMIT = 255;
  const sourceData = thisModule.getSourceData();
  const utmSourceSubstring = 'source=';

  if (!sourceData) {
    return '';
  }
  const extractedUtmSource = sourceData.split('|')
    .find(item => item.includes(utmSourceSubstring)) || '';
  const utmSourceValue = extractedUtmSource.slice(utmSourceSubstring.length) || '';

  return utmSourceValue.substring(0, CHAR_LIMIT);
}

export function refreshSession () {
  // implementation based on:
  // https://support.google.com/analytics/answer/2731565?hl=en

  const EXPIRE_TIME = 1800000;

  const midnight = new Date().setHours(24, 0, 0, 0);
  const standardSession = Date.now() + EXPIRE_TIME;
  if (midnight < standardSession) {
    storage.setItem('sessionSourceExpiresAt', midnight);
  } else {
    storage.setItem('sessionSourceExpiresAt', standardSession);
  }
}
