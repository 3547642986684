import { del, post } from './request';

export default {
  saveUser (data) {
    return post('/my/user/save', { data });
  },

  deleteUser () {
    return del('/my/user');
  },

  initUser (source = 'undefined') {
    return post(`/my/user/init?userRegisterSource=${source}`, { silenceErrors: true, case_sensitive: true });
  },

  postChangePassword (data) {
    return post('/my/user/change-password', { data });
  },
};
