<template>
  <div class="transparent condensed card">
    <div class="fixed content">
      <img
        v-if="review.avatar"
        v-lazy="review.avatar"
        class="ui left floated avatar image"
        :alt="userName"
      >
      <aj-icon
        v-else
        shape="user"
        class="ui left floated avatar image"
      />
      <div class="ui left floated medium header">
        <div class="username">
          {{ userName }}
        </div>
        <div class="userCity">
          {{ userCity }}
          <flag-icon :country-code="review.city.country.countryCode" />
        </div>
      </div>
    </div>
    <div class="rating-stars">
      <aj-rating
        :initial-rating="review.rating"
        show-value
      />
    </div>
    <div class="quote content">
      <router-link
        v-if="showLink(review)"
        class="ui spaceless header"
        :to="{
          name: 'job_offer',
          params: {
            citySlug: review.city.slug, partnerSlug: review.partner.slug,
          },
        }"
      >
        {{ review.partner.name }} - {{ review.city.name }}
      </router-link>
      <div
        v-if="review.review"
        class="description"
      >
        {{ review.review }}
      </div>
    </div>
    <div
      v-if="canEdit"
      class="edit-review"
      @click="reviewMixin_editReview(review, offer)"
    >
      <aj-button
        primary
        v-text="translate('reviews.buttonLabel.editReview')"
      />
    </div>
  </div>
</template>

<style lang="less">
  .ui.header {
    font-size: 1rem;
  }

  .ui.cards > .card .ui.image.avatar {
    width: 3em;
    height: 3em;
  }

  .ui.cards .card > .content.fixed .header {
    margin: 0.1em auto;
  }

  .ui.cards > .card > .content.quote {
    padding-top: 0;
    padding-left: 0;
  }

  .username {
    padding-left: 0.3em;
    font-weight: normal;
    font-size: 14px;
  }

  .userCity{
    padding-left: 0.3em;
    font-weight: normal;
    font-size: 12px;
    color: @darkerGrey;
}

  /* with context job offer page */
  .job-offer.card {
    margin: 20px 0 40px;
    position: relative;
  }

  .job-offer.card .ui.image.avatar {
    width: 2.8em;
    height: 2.8em;
  }
   .job-offer.card > .rating-stars {
     clear: both;
     margin-bottom: 0.5em;
   }

  .job-offer.card > .content.quote {
    color: @lightBlack;
  }

  .edit-review {
    color: @orange;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: @largestMobileScreen) {
      position: static;
      margin: 1em 0;
    }
  }
</style>

<script>
import { mapGetters } from 'vuex';
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjRating from '@appjobs/ui/src/components/AjRating/AjRating';
import reviewMixin from '@/js/mixins/review-mixin';
import profileMixin from '@/js/mixins/profile-mixin';
import FlagIcon from '@/js/components/ui/flag-icon';

export default {
  name: 'ReviewItem',

  components: {
    AjButton,
    AjRating,
    AjIcon,
    FlagIcon,
  },

  mixins: [profileMixin, reviewMixin],

  props: {
    review: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...mapGetters(['isUser']),

    userName () {
      const name = this.review.username.replace('undefined', '').replace(' .', '').trim();

      if (!name || name === '.') {
        return 'Anonymous';
      }

      return name;
    },

    userCity () {
      return `${this.review.city.name}, ${this.review.city.country.name}`;
    },

    canEdit () {
      return this.offer && this.isUser && this.review.userId === this.profileMixin_userId;
    },
  },

  methods: {
    showLink (review) {
      return review.city && review.partner && review.city.name && review.partner.name;
    },
  },
};
</script>
