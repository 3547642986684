import { get, patch } from './request';

export default {
  updateUserLangs (payload) {
    if (!payload) {
      return undefined;
    }

    return patch('/my/languages', {
      authorize: true,
      data: payload,
    });
  },

  getUserLangs () {
    return get('/my/languages?expand=level');
  },

  getLangs () {
    return get('/languages');
  },
};
