const { slugify } = require('./str');

/**
 * Returns the country code from an array of countries that matches the provided slug.
 * If the slug is not provided or if there is no match, an empty string is returned.
 *
 * @param {Object[]} countriesArray - An array of country objects.
 * @param {string} countriesArray[].countryCode - The code of the country.
 * @param {string} [countrySlug=''] - The slug of the country to search for.
 * @returns {string} The code of the country, or null string if no slug match is found.
 */

const getCountryCodeBySlug = (countriesArray, countrySlug = '') => {
  if (!countrySlug) {
    return null;
  }

  const lowerCaseSlug = countrySlug.toLowerCase();
  const filteredCountry = countriesArray
    .find(country => slugify(country.name) === lowerCaseSlug);

  return filteredCountry?.countryCode || null;
};

/**
 * Returns the country name from an array of countries that matches the provided slug.
 * If the slug is not provided or if there is no match, an empty string is returned.
 *
 * @param {Object[]} countriesArray - An array of country objects.
 * @param {string} countriesArray[].name - The name of the country.
 * @param {string} [countrySlug=''] - The slug of the country to search for.
 * @returns {string} The name of the country, or an empty string if no slug match is found.
 */

const getCountryNameBySlug = (countriesArray, countrySlug = '') => {
  if (!countrySlug) {
    return '';
  }

  const lowerCaseSlug = countrySlug.toLowerCase();
  const filteredCountry = countriesArray
    .find(country => slugify(country.name) === lowerCaseSlug);

  return filteredCountry?.name || '';
};

module.exports = {
  getCountryCodeBySlug,
  getCountryNameBySlug,
};
