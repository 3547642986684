<template>
  <div class="headers">
    <header
      v-if="!hideElements && !isRedirect"
      class="ui spaceless bottom top fixed inverted menu"
      :class="{
        '-scrolled': scrolledAway,
      }"
      :style="`top: ${offsetTop}px`"
    >
      <container-wrapper class="fluid">
        <div class="logo-wrapper">
          <router-link
            :to="{ path: routesMixin_homePageRoutePath }"
            class="header item"
            :title="translate('seo.title.home')"
          >
            <aj-icon
              :shape="logoIcon"
              class="ui logo"
            />
          </router-link>
        </div>
        <menu-inline-header v-if="!isMobile" />
        <div class="item right">
          <span
            v-if="language"
            class="language"
          >
            <flag-icon
              v-if="languageCode && !isMobile"
              :country-code="languageCode"
            />
            <language-selector
              v-if="!isMobile"
              class="item pointing"
            />
          </span>
          <auth-button
            context="topnavigation"
            class="top-nav"
          />
        </div>
      </container-wrapper>
    </header>
    <div
      class="dim"
      :class="{ darkbgr: openedMenu }"
    />
  </div>
</template>

<style lang="scss" scoped>
  header.ui.inverted.menu {
    background: transparent;
    &.-scrolled {
      background-color: $cl-mako;
    }

    .container.fluid {
      padding-left: 49px;
      padding-right: 49px;

      @media (max-width: $tablet) {
        padding-right: 15px;
      }
    }

    .logo-wrapper {
      text-align: -webkit-right;
      margin-right: 117px;

      @media (max-width: $desktop-small) {
        margin-right: 70px;
      }

      @media (max-width: $tablet) {
        margin-right: 0;
      }

      .ui.logo {
        width: 125px;
        height: 40px;
        object-fit: contain;
      }
    }
  }
</style>

<style lang="less" scoped>
  .ui.menu .item.right {
    flex: 1 1 auto;
    transition: font-size 500ms;
    justify-content: flex-end;
  }

  @media only screen and (max-width: @largestMobileScreen) {
    .ui.logo {
      width: 100px;
    }

    .ui.container.fluid {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  .logo-wrapper {
    flex-direction: column;
    .header.item {
      padding: 0;
      height: 100%;
    }
  }

  .dim {
    display: none;
  }

  .dim.darkbgr {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  .item.right {
    justify-content: flex-end;
  }

  .item.right > .language {
    display: contents;
  }

  header.ui.menu {
    flex-wrap: wrap;

    .item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: @largestMobileScreen) {
    header.ui.inverted.menu {
      .container.fluid {
        padding-left: 0;

        .header.item {
          padding: 0;
        }

        .ui.logo {
          width: 100px;
          height: 32px;
          object-fit: contain;
          margin-left: 15px;
        }
      }
    }

    .item.right.newVariant {
      .top-nav {
        padding-bottom: 10px;
      }
    }
  }
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import FlagIcon from '@/js/components/ui/flag-icon';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import authButton from '@/js/components/auth-button';
import menuInlineHeader from '@/js/components/menu-inline-header';
import LanguageSelector from '@/js/components/selectors/language-selector';
import { isMobile } from '@/js/helpers/breakpoints';
import { redirectRoutes, routesWithRedesign } from '@/js/helpers/routes';
import { EventBus } from '@/js/helpers/utils';
import routesMixin from '@/js/mixins/routes-mixin';

export default {
  name: 'MenuHeader',

  components: {
    authButton,
    ContainerWrapper,
    LanguageSelector,
    menuInlineHeader,
    FlagIcon,
    AjIcon,
  },

  mixins: [routesMixin],

  props: {
    offsetTop: Number,
  },

  data () {
    return {
      scrolledAway: false,
      openedMenu: false,
    };
  },

  computed: {
    ...mapState({
      messages: state => state.messages,
    }),
    ...mapGetters('modal', ['hideElements']),
    ...mapState('i18n', ['language']),

    isMobile () {
      return isMobile();
    },

    languageCode () {
      if (this.language === 'en') {
        return 'gb';
      }
      if (this.language === 'sv') {
        return 'se';
      }

      return this.language;
    },

    isRedirect () {
      return redirectRoutes.includes(this.$route.name);
    },

    logoIcon () {
      return !this.scrolledAway && routesWithRedesign.includes(this.$route.name) ? 'logo' : 'logo-white';
    },
  },

  created () {
    EventBus.$on('body-scrolled', this.updateMenuDisplay);
    EventBus.$on('opened-new-menu', this.openMenu);
    EventBus.$on('close-new-menu', this.closeMenu);
  },

  destroyed () {
    EventBus.$off('body-scrolled', this.updateMenuDisplay);
    EventBus.$off('opened-new-menu', this.openMenu);
    EventBus.$off('close-new-menu', this.closeMenu);
  },

  methods: {
    updateMenuDisplay (position) {
      this.scrolledAway = position > 30;
    },

    openMenu () {
      if (this.isMobile && !this.openedMenu) {
        this.openedMenu = true;
      }
    },

    closeMenu () {
      if (this.isMobile) {
        this.openedMenu = false;
      }
    },
  },
};
</script>
