const DEFAULT_TITLE = 'Appjobs';

export function seoTitle (title = null) {
  window.document.title = title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
}

export function setCanonicalUrl (canonicalUrl) {
  let canonical = document.querySelector('link[rel="canonical"]');

  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }

  canonical.setAttribute('href', canonicalUrl);
}
