import { get } from '@/js/services/request';

export default {
  getPartnerBySlug (partnerSlug) {
    return get(`/partners/slug/${partnerSlug}`);
  },

  getPartners (params = {}) {
    return get('/partners', { params });
  },
};
