export const ADD_REVIEW = 'addReview';
export const AUTH = 'Auth';
export const CHANGE_PASSWORD = 'ChangePassword';
export const COOKIE_PREFERENCES = 'CookiePreferences';
export const DELETE_PROFILE = 'DeleteProfileConfirm';
export const DIRECT_APPLY = 'DirectApply';
export const EARNINGS_OFFER = 'earnings-offer';
export const FAVOURITE_CATEGORIES = 'favouriteCategories';
export const FILTERS_MODAL = 'filtersModal';
export const PARTNER_INDEX_FILTERS_MODAL = 'partnerIndexFiltersModal';
export const OFFER_AVAILABILITY = 'customerAvailability';
export const OFFER_TILE_REVIEWS = 'offer-tile-reviews';
export const ONBOARDING = 'Onboarding';
export const REQUEST_CITY_CONFIRM = 'requestCityConfirm';
export const RESET_PASSWORD = 'ResetPassword';
export const REVIEW_DETAILS = 'reviewDetails';
export const SET_WORKTOWN = 'setWorktown';
export const SET_USER_LANGUAGE = 'setUserLanguage';
export const SET_USER_LANGUAGE_LEVEL = 'setUserLanguageLevel';
export const SIGNUP_LENGTH_OFFER = 'signup-length-offer';
export const UNVERIFIED_APPLY = 'UnverifiedApply';
