import { mapActions, mapState, mapGetters } from 'vuex';
import { setComponentName } from '@/js/helpers/utils';
import { sendGAEvent } from '@/js/helpers/analytics';
import { ADD_REVIEW, REVIEW_DETAILS } from '@/js/enums/modal';

export default {
  computed: {
    ...mapGetters(['isUser']),
    ...mapState('jobOffers', ['myJobOffers', 'isMyOffersLoaded']),
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('auth', ['showAuthBox']),

    reviewMixin_addReview (offer, context, initialRates = [], citiesOptions = [], partner = {}) {
      sendGAEvent({
        category: 'AddReviewButton',
        action: 'click',
        label: this.$route.name,
      });

      if (!this.isUser) {
        setComponentName(this.$options.name);
        this.showAuthBox({ path: window.location.pathname, data: { source: 'AddReview' } });

        return;
      }

      this.showModal({
        name: ADD_REVIEW,
        data: {
          offer,
          context,
          rates: initialRates,
          citiesOptions,
          partner,
        },
      });
    },

    reviewMixin_editReview (review, offer) {
      this.showModal({
        name: ADD_REVIEW,
        data: {
          review,
          offer,
        },
      });
    },

    reviewMixin_showReviewDetails (offer) {
      this.showModal({ name: REVIEW_DETAILS, data: { offer } });
    },

    reviewMixin_canAddReviewForOffer (offer, isModal) {
      if (isModal) {
        return true;
      }

      if (!this.isUser) {
        return true;
      }

      if (!this.isMyOffersLoaded) {
        return false;
      }

      if (this.myJobOffers.length === 0) {
        return true;
      }

      return !this.myJobOffers.some(myOffer => myOffer.id === offer.id && myOffer.review);
    },
  },
};
