import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import partner from '@/js/store/modules/partner';
import { storage } from '../helpers/storage';
import cookieNotice from './modules/cookieNotice';
import auth from './modules/auth';
import authBox from './modules/authBox';
import blog from './modules/blog';
import breadcrumbs from './modules/breadcrumbs';
import categories from './modules/categories';
import cityCategory from './modules/cityCategory';
import cities from './modules/cities';
import city from './modules/city';
import directApply from './modules/directApply';
import googleRecaptcha from './modules/googleRecaptcha';
import i18n from './modules/i18n';
import jobApply from './modules/jobApply';
import jobOffer from './modules/jobOffer';
import jobOffers from './modules/jobOffers';
import modal from './modules/modal';
import moreJobs from './modules/moreJobs';
import onboarding from './modules/onboarding';
import resetPassword from './modules/resetPassword';
import userLangs from './modules/userLangs';

Vue.use(Vuex);

// EXPERIMENTAL: purge old values
if (storage.getItem('vuex')) {
  storage.clear();
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex_appjobs', // clear old values above if/when changing this key
      storage,
      setState: (key, state) => storage.setItem(key, JSON.stringify(state)),

      paths: [
        'auth', // full auth state
        'authBox', // full authBox state
        'i18n.language',
        'cookieNotice',
        'comparison',
        'directApply',
        'onboarding',
        'jobApply',
      ],
    }),
  ],

  state: {
    jobRequirements: [],
    messages: [],
  },

  modules: {
    auth,
    authBox,
    blog,
    breadcrumbs,
    categories,
    cities,
    city,
    cityCategory,
    cookieNotice,
    directApply,
    googleRecaptcha,
    i18n,
    jobApply,
    jobOffer,
    jobOffers,
    modal,
    moreJobs,
    onboarding,
    partner,
    resetPassword,
    userLangs,
  },

  getters: {
    isUser: state => (!!state.auth.idToken && state.auth.expiresAt > Date.now()),
    profile: state => state.auth.profile,
    directApplyGuestData: state => state.directApply.guestData,
    userSessionId: () => auth.actions.initSessionId(),
    referralUrl (state) {
      const profileData = (state.auth.profile && state.auth.profile.user_metadata) || {};
      if (profileData.referral_data && profileData.referral_data.expires_at > Date.now()) {
        return profileData.referral_data.url;
      }

      const cookieReferralData = Cookies.get('referralData');
      if (cookieReferralData) {
        return JSON.parse(cookieReferralData).url;
      }

      return undefined;
    },
    isPushableMessage: state => (!!state.messages.find(item => item.pushable === true)),
  },

  mutations: {
    setJobRequirements (state, jobRequirements) {
      state.jobRequirements = jobRequirements;
    },

    filterJobRequirements (state, jobRequirementsIds) {
      state.jobRequirements.forEach((item, index, arr) => {
        const select = jobRequirementsIds.indexOf(item.id) > -1;
        const newValue = { ...item, _select: select };

        // Make Vue aware of array change.
        // Details: https://vuejs.org/v2/guide/list.html#Caveats
        Vue.set(arr, index, newValue);
      });
    },

    setMessage (state, {
      message,
      title,
      variation,
      html,
      closable,
      actionLabel,
      actionHandler,
      closeHandler,
      source,
      pushable,
    }) {
      const flashMessage = {
        id: state.messages.length + 1,
        title,
        variation,
        message,
        actionLabel,
        actionHandler,
        closeHandler,
        html: html || false,
        closable: typeof closable === 'undefined' ? true : closable,
        source,
        pushable: typeof pushable === 'undefined' ? true : pushable,
      };

      state.messages.push(flashMessage);
    },

    deleteMessage (state, messageId) {
      state.messages.splice(state.messages.findIndex(message => message.id === messageId), 1);
    },

    clearMessages (state) {
      state.messages = [];
    },
  },

  actions: {
    setMessage ({ commit }, messageData) {
      commit('cities/stopLoadingCities');
      commit('jobOffers/stopLoadingOffers');
      commit('setMessage', messageData);
    },

    deleteMessage ({ commit }, messageId) {
      commit('deleteMessage', messageId);
    },

    clearMessages ({ commit }) {
      commit('clearMessages');
    },
  },
});
