<template>
  <!-- Component for banners / messages at the top of the page
       Previously was used for work app banner and Ukrainian help banner
       Check AC-1505 for more info
  -->
  <div class="topBar" />
</template>

<style lang="scss" scoped>
  .topBar {
    position: fixed;
    z-index: 900;
    top: 0;
    width: 100%;
  }
</style>

<script>

export default {
  name: 'TopBar',

  async created () {
    this.emitTopBarHeight();
  },

  methods: {
    emitTopBarHeight () {
      this.$nextTick(() => {
        this.$emit('heightChange');
      });
    },
  },
};
</script>
