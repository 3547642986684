import { get } from '@/js/services/request';
import { sentryCaptureException } from '@/js/helpers/sentry';
import store from '@/js/store/index';
import { google } from '../../../config/auth';
import { sessionStorage } from '../helpers/storage';

let memoizedCoordinates = null;

// eslint-disable-next-line no-unused-vars
const googlePromise = () => new Promise((resolve, reject) => {
  if (window.google && window.google.maps) {
    resolve();

    return;
  }

  const script = document.createElement('script');
  script.src = `//maps.googleapis.com/maps/api/js?key=${google.apiKey}`;
  script.async = true;
  script.defer = true;

  script.onload = function () {
    if (!window.google || !window.google.maps) {
      reject(new Error('Google maps script not loaded.'));
    }

    resolve();
  };

  document.body.appendChild(script);
});

const getCoordinatesByIp = () => new Promise((resolve, reject) => {
  const apiKey = process.env.VUE_APP_IP_GEOLOCATION_SERVICE_API_KEY;
  const apiUrl = process.env.VUE_APP_IP_GEOLOCATION_SERVICE_API_URL;
  const endpoint = process.env.NODE_ENV === 'production'
    ? '/ip_lookup' : `${apiUrl}?key=${apiKey}`;
  get(endpoint, {
    target: 'external',
    type: 'form',
    authorize: false,
    silenceErrors: true,
    headers: {
      'Cache-Control': 'no-cache',
      Expires: 0,
    },
  }).then(result => {
    if (result.status === 'fail') {
      reject();
    }
    resolve({
      lat: result.lat,
      lng: result.lon,
      country: result.country,
    });
  }, error => {
    sentryCaptureException(error);
    reject(error);
  });
});

const getCoordinatesFallback = async () => {
  let data;
  try {
    data = await getCoordinatesByIp();
  } catch (e) {
    sentryCaptureException(e);
    return {
      lat: store.state.city.lat || 0,
      lng: store.state.city.lng || 0,
    };
  }

  return data;
};

export default {
  async getCoordinates () {
    if (memoizedCoordinates) {
      return memoizedCoordinates;
    }

    let lastKnownCoords = sessionStorage.getItem('lastKnownCoords');

    if (lastKnownCoords) {
      lastKnownCoords = JSON.parse(lastKnownCoords);
      if (lastKnownCoords.lat && lastKnownCoords.lng) {
        memoizedCoordinates = lastKnownCoords;
        return memoizedCoordinates;
      }
    }

    memoizedCoordinates = new Promise(resolve => {
      if (process.env.VUE_APP_GEO_NAVIGATOR && navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          // user agreed to share location
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }, async () => {
          const data = await getCoordinatesFallback();
          resolve(data);
        });
      } else {
        resolve(getCoordinatesFallback());
      }
    }).then(coordinates => {
      sessionStorage.setItem('lastKnownCoords', JSON.stringify(coordinates));

      return coordinates;
    });
    return memoizedCoordinates;
  },
};
