<template>
  <div
    class="ui bubble"
    :class="shape"
  >
    <div
      v-if="name"
      class="name"
    >
      {{ name }}
    </div>
    <div
      class="icon size"
      :class="size"
    >
      <aj-icon
        v-if="background"
        shape="bubble"
        class="background size"
        :class="size"
      />
      <aj-icon
        class="pictogram size"
        :class="[size, shape]"
        :shape="shape"
      />
    </div>
  </div>
</template>

<script>
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';

export default {
  name: 'BubbleIcon',

  components: {
    AjIcon,
  },

  props: {
    name: {
      type: String,
    },

    size: {
      type: String,
      default: 'medium medium-desktop',
    },

    shape: {
      type: String,
      required: true,
    },

    background: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
