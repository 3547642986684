import { storage } from '@/js/helpers/storage';
import { gtag } from '@/js/helpers/analytics';
import store from '@/js/store';

export function consentModeUpdate () {
  const cookiesForm = store.state?.cookieNotice.cookiesConsentForm;
  const consentMode = {
    ad_personalization: cookiesForm.marketing ? 'granted' : 'denied',
    ad_storage: cookiesForm.marketing ? 'granted' : 'denied',
    ad_user_data: cookiesForm.marketing ? 'granted' : 'denied',
    analytics_storage: cookiesForm.analytics ? 'granted' : 'denied',
    functionality_storage: cookiesForm.essential ? 'granted' : 'denied',
    personalization_storage: cookiesForm.preferences ? 'granted' : 'denied',
    security_storage: cookiesForm.essential ? 'granted' : 'denied',
  };

  gtag('consent', 'update', consentMode);
  storage.setItem('consentMode', JSON.stringify(consentMode));
}
