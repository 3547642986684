<template>
  <div>
    <div id="overview" />
    <div
      v-if="overview && !hideElements"
      class="ui vertical small not separated stripe white segment"
    >
      <container-wrapper class="text">
        <div class="blog">
          <container-wrapper class="relaxed">
            <infographic-section
              type="work"
              class="small emphasis"
              text-prefix="work"
            />
          </container-wrapper>
          <container-wrapper class="relaxed">
            <overview-content :html="overview" />
          </container-wrapper>
        </div>
      </container-wrapper>
    </div>

    <div
      v-if="jobOffers.length"
      class="ui vertical small not separated stripe white segment"
    >
      <container-wrapper class="center aligned large primary text">
        <p v-html="translate('cityCategory.checkOtherCategories', { cityName: city.name })" />
        <aj-button
          class="cityCategory__moreCategories"
          subtle-light
          large
          tag="router-link"
          :to="{ name: 'city', params: { citySlug: city.slug } }"
          :title="city.header || translate('seo.title.city', { cityName: city.name })"
        >
          {{ translate('cityCategory.button.moreCategories') }}
        </aj-button>
      </container-wrapper>
    </div>

    <reviews-segment
      v-if="city.ratingCount"
      :reviews="reviews"
      class="with-rating"
      :force-title-show="true"
    >
      <template slot="header">
        {{ translate('cityCategory.reviews.header', {
          categoryName: category.name, cityName: city.name,
        }) }}<br>
        <span class="category-rating">
          <category-icon
            :slug="category.orgSlug"
            class="item"
            size="normal"
          />
          <span class="item">
            <rating-info
              v-if="rating"
              small
              :initial-rating="rating"
              :rating-count="ratingCount"
              show-value
            />
            <div>{{ category.name }} - {{ city.name }}</div>
          </span>
        </span>
      </template>
    </reviews-segment>

    <city-category-interlinking />

    <more-jobs-banner
      v-if="showMoreJobsBanner"
      class="city__moreJobsBanner"
      :city="city"
      :source-route="$route.name"
    />
  </div>
</template>

<style lang="scss" scoped>
.cityCategory__moreCategories {
  display: inline-block;
}
</style>

<style lang="less" scoped>
  .ui.desktop.sized.fluid.container {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .masthead {
    h1.ui.header {
      font-size: 1.6rem;

      @media only screen and (min-width: 769px) {
        font-size: 2.4rem;
      }
    }
  }

  .ui.container.fluid.dark {
    padding-top: 0;
  }

  .ui.container.text.narrow {
    margin-top: 1em;
  }

  .category-rating {
    display: inline-flex;
    font-size: 1rem;
    line-height: 1;
    text-align: left;
    padding: 20px 0;
    border-bottom: 1px solid @darkGrey;
  }
</style>

<script>
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import { mapGetters, mapState } from 'vuex';
import RatingInfo from '@/js/components/ui/rating-info';
import CityCategoryInterlinking from '@/js/views/CityCategory/city-category-interlinking';
import MoreJobsBanner from '@/js/components/banners/more-jobs-banner';
import InfographicSection from '@/js/components/infographic-section';
import ContainerWrapper from '@/js/components/ui/container-wrapper';
import OverviewContent from './overview-content';
import ReviewsSegment from './job-offer-page/reviews-segment';
import categoryIcon from './category-icon';

export default {

  components: {
    AjButton,
    RatingInfo,
    ContainerWrapper,
    InfographicSection,
    OverviewContent,
    ReviewsSegment,
    categoryIcon,
    CityCategoryInterlinking,
    MoreJobsBanner,
  },

  computed: {
    ...mapGetters('modal', ['hideElements']),
    ...mapState('jobOffers', ['jobOffers']),
    ...mapState('cityCategory', ['id', 'city', 'category', 'header', 'overview', 'rating', 'ratingCount', 'reviews']),

    notVerifiedOffers () {
      return this.jobOffers.filter(offer => !offer.isVerified);
    },

    showMoreJobsBanner () {
      return this.notVerifiedOffers.length === 0;
    },
  },
};

</script>
