<template>
  <div>
    <aj-loader
      :show="isLoadingPartners"
      class="componentLoader"
    />
    <partner-index />
  </div>
</template>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { seoTitle } from '@/js/helpers/seo';
import { translate } from '@/js/helpers/i18n';
import { EventBus } from '@/js/helpers/utils';
import store from '@/js/store';

async function processFilters (route) {
  await processFilteredCategory(route);
}

async function processFilteredCategory (route) {
  const filteredCategory = route.query.category;
  if (filteredCategory) {
    await store.dispatch('partner/setFilteredCategory', filteredCategory);
  } else {
    await store.dispatch('partner/clearFilteredCategory');
  }
}

function setSeoTitle (page) {
  let title = translate('mainGigsTab.companies');
  page = page ? parseInt(page.split('page-')[1]) : 1;

  if (page > 1) {
    title += ` - ${translate('pagination.page', { number: page })}`;
  }

  seoTitle(title);
}

export default {
  name: 'PartnerIndexRoute',

  data () {
    return {
      isLoadingPartners: true,
    };
  },

  components: {
    PartnerIndex: () => import('@/js/views/PartnerIndex/partner-index-view'),
    AjLoader,
  },

  created () {
    EventBus.$on('partners-loaded', () => {
      this.isLoadingPartners = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await processFilters(to);
    setSeoTitle(to.params.page);
    next();
  },

  async beforeRouteUpdate (to, from, next) {
    setSeoTitle(to.params.page);
    next();
  },
};
</script>
