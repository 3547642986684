<template>
  <div>
    <aj-loader
      :show="isCityLoading"
      class="componentLoader"
    />
    <city-view />
  </div>
</template>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { mapState } from 'vuex';
import { EventBus } from '@/js/helpers/utils';
import store from '../store';
import { seoTitle } from '../helpers/seo';
import { translate } from '../helpers/i18n';

async function fetchData ({ citySlug }) {
  if (citySlug !== store.state.city.slug) {
    await store.dispatch('city/getCityBySlug', {
      citySlug,
      expand: 'details',
    });
  }
}

async function processFilters (route) {
  EventBus.$emit('clearJobOffersFilters');
  await processJobOffersFilteredCategories(route);
  await processJobOffersFilteredType(route);
}

async function processJobOffersFilteredCategories (route) {
  const filteredCategoriesSlugs = route.query.categories;
  if (filteredCategoriesSlugs) {
    if (!store.state.categories.cityCategories.length) {
      await store.dispatch('categories/getCityCategories', route.params.citySlug);
    }
    const filteredCategoriesIds = store.state.categories.cityCategories.reduce((prev, cur) => {
      if (filteredCategoriesSlugs.indexOf(cur.category.slug) > -1) {
        prev.push(cur.category.id);
      }

      return prev;
    }, []);
    await store.dispatch('jobOffers/setFilteredCategories', filteredCategoriesIds);
  } else {
    await store.dispatch('jobOffers/clearFilteredCategories');
  }
}

async function processJobOffersFilteredType (route) {
  const filteredType = route.query.type;
  if (filteredType) {
    await store.dispatch('jobOffers/setFilteredType', filteredType);
  } else {
    await store.dispatch('jobOffers/clearFilteredType');
  }
}

function setSeoTitle (city, { page }) {
  let title = city.header || translate('seo.title.city', { cityName: city.name });
  page = page ? parseInt(page.split('page-')[1]) : 1;

  if (page > 1) {
    title += ` - ${translate('pagination.page', { number: page })}`;
  }

  seoTitle(title);
}

export default {
  name: 'CityRoute',

  data () {
    return {
      isCityLoading: true,
    };
  },

  computed: {
    ...mapState(['city']),
  },

  created () {
    EventBus.$on('city-loaded', () => {
      this.isCityLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await fetchData(to.params);
    await processFilters(to);
    if (!store.state.city.id) {
      next({
        name: 'not_found',
      });
    } else {
      setSeoTitle(store.state.city, to.params);

      next();
    }
  },

  async beforeRouteUpdate (to, from, next) {
    await fetchData(to.params);
    setSeoTitle(store.state.city, to.params);

    next();
  },

  components: {
    AjLoader,
    CityView: () => import('@/js/views/City/city-view'),
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
