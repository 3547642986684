import { mapState } from 'vuex';
import blogConfig from '@/../config/blog';

export default {

  computed: {
    ...mapState('city', {
      citySlug: state => state.slug,
    }),
    ...mapState('i18n', ['language']),

    routesMixin_homePageRoutePath () {
      const { href } = this.$router.resolve({ name: 'home' });

      return href.length > 1 ? href.slice(0, -1) : href;
    },

    routesMixin_gigsRouteObject () {
      // profileMixin_worktownSlug is accessible in components where both mixins are imported
      if (!this.profileMixin_worktownSlug && this.$route.name !== 'city') {
        return {
          path: this.routesMixin_homePageRoutePath,
        };
      }

      return {
        name: 'city',
        params: { citySlug: this.profileMixin_worktownSlug || this.citySlug },
      };
    },

    routesMixin_blogRoutePath () {
      const routerParams = {
        name: 'blog',
      };
      if (blogConfig.alternativeLanguageVersions.includes(this.language)) {
        routerParams.query = {
          lang: this.language,
        };
      }
      let { href } = this.$router.resolve(routerParams);

      if (this.language !== 'en') {
        href = href.slice(3);
      }

      return href;
    },
  },
};
