import queryString from 'query-string';
import { get, post } from './request';

export default {
  getCities () {
    return get('/cities/', { authorize: false, errorMessage: 'Cannot load cities.' });
  },

  getNearbyCities (params) {
    return get('/cities', { authorize: false, silenceErrors: true, params });
  },

  getCity ({ cityId }) {
    return get(`/cities/id/${cityId}`, { authorize: false, silenceErrors: true });
  },

  getCityBySlug ({ citySlug }, params = {}) {
    if (params) {
      params = `?${queryString.stringify(params)}`;
    }

    return get(`/cities/slug/${citySlug}${params}`, { authorize: false, silenceErrors: true });
  },

  getTopCities () {
    return get('/cities/top', { authorize: false });
  },

  getNewestCities () {
    return get('/cities/newest', { authorize: false });
  },

  addRequestedCity (city) {
    const data = {
      proposition: city,
    };

    return post('/cities/proposition', { silenceErrors: true, data, sendSessionId: true });
  },
};
