import { getFirstFromJobOffer } from '@/js/helpers/category';
import Cookies from 'js-cookie';
import { storage, localStorageSupported } from './storage';
import { refreshSession } from './gap';
import analyticsService from '../services/analytics';
import store from '../store';

export function getUserId () {
  const userProfile = store.state?.auth?.profile;
  const cookieUserId = Cookies.get('userId');

  const userId = userProfile?.user_id || cookieUserId || null;

  return userId;
}

export function sendGAEvent (data) {
  refreshSession();
  data = data || {};
  const eventCustomParams = { ...data.customParams };

  // prevent sending empty event
  if (!data.event && (!data.category || !data.action)) {
    return undefined;
  }

  window.dataLayer = window.dataLayer || [];

  return new Promise(resolve => {
    if (process.env.NODE_ENV === 'development') {
      // Useful in .env.local
      if (process.env.VUE_APP_LOG_EVENT === 'true') {
        // eslint-disable-next-line no-console
        console.log('Google Analytics Event', { ...data, event: data.event || 'GAEvent' });
      }
      resolve();
    }

    const timeout = setTimeout(resolve, 3000);
    window.dataLayer.push({
      event: data.event || 'GAEvent',
      eventCategory: data.category,
      eventAction: data.action,
      eventLabel: data.label,
      eventValue: data.value,
      eventUserId: getUserId(),
      ...eventCustomParams,
      eventCallback: () => {
        clearTimeout(timeout);
        resolve();
      },
    });
  });
}

export function storeEvent (data) {
  if (localStorageSupported && data) {
    storage.setItem('ga_event', JSON.stringify(data));
  }
}

export function triggerStoredEvent () {
  if (localStorageSupported) {
    const data = storage.getItem('ga_event');
    if (data) {
      sendGAEvent(JSON.parse(data));
      storage.removeItem('ga_event');
    }
  }
}

export function sendGATransaction (transactionObj) {
  refreshSession();

  return new Promise(resolve => {
    const timeout = setTimeout(resolve, 500);

    if (!transactionObj.id
      || !Array.isArray(transactionObj.products)
      || !transactionObj.products.length) {
      return;
    }

    const eventData = {
      event: 'EETransaction',
      eventCallback: () => {
        clearTimeout(timeout);
        resolve();
      },
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionObj.id,
            revenue: transactionObj.revenue,
            list: transactionObj.list,
            userId: getUserId(),
          },
          products: transactionObj.products,
        },
      },
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);

    if (process.env.NODE_ENV === 'development') {
      // Useful in .env.local
      if (process.env.VUE_APP_LOG_EVENT === 'true') {
        // eslint-disable-next-line no-console
        console.log('Google Analytics Event', { ...eventData, event: eventData.event || 'EETransaction' });
      }
      resolve();
    }
  });
}

// https://developers.google.com/tag-manager/enhanced-ecommerce#details
export function sendGADetail (products) {
  if (!(Array.isArray(products) && products.length)) {
    console.warn('Products must be non-empty array!');

    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'EEDetails',
    ecommerce: {
      detail: {
        products,
      },
    },
  });
}

// https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
export function sendGAImpression (products) {
  if (!(Array.isArray(products) && products.length)) {
    console.warn('Products must be non-empty array!');

    return;
  }

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'EEImpressions',
    ecommerce: {
      impressions: products,
    },
  });
}

export function clearContentGrouping () {
  if (window.dataLayer) {
    window.dataLayer.push({
      contentGroup1: undefined,
      contentGroup2: undefined,
    });
  }
}

export function setContentGrouping (data) {
  window.dataLayer = window.dataLayer || [];

  const key = `contentGroup${data.index}`;
  window.dataLayer.push({
    [key]: data.group,
  });
}

export function jobOfferToProduct (offer) {
  const offerCategory = getFirstFromJobOffer(offer);
  const product = {
    item_id: offer.id,
    item_name: `${offer.city.orgSlug} / ${offer.partner.orgSlug}`,
    price: offer.weight > 0 ? offer.weight / 100 : 0,
    item_category: offerCategory.orgSlug,
    item_brand: offer.partner.orgSlug,
    quantity: 1,
    clickContext: `${offer.clickSource}-${offer.buttonType}`,
  };

  let list = offer.context || null;
  if (offer.context === 'city') {
    list = offer.city.orgSlug;
  } else if (offer.context === 'city-category') {
    list = `${offer.city.orgSlug}-${offer.categories[0].orgSlug}`;
  }

  if (list) {
    product.item_list_id = list;
  }

  if (offer.position) {
    product.index = offer.position;
  }

  return product;
}

export function sendPageView (data) {
  refreshSession();
  analyticsService.sendPageView(data);
}

export function gtag () {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_LOG_EVENT === 'true') {
    // eslint-disable-next-line no-console
    console.log('gtag event', { ...arguments });
  }
}
