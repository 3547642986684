import Cookies from 'js-cookie';
import router from 'router';
import store from '@/js/store';
import storageEnum from '@/js/enums/storage';
import { sendGAEvent } from '@/js/helpers/analytics';
import { sentryCaptureException } from '@/js/helpers/sentry';
import { sessionStorage } from '@/js/helpers/storage';
import { EventBus } from '@/js/helpers/utils';
import userService from '@/js/services/user';

function isPropertyNotExistInMetadata (profile, property) {
  return !profile.user_metadata || !profile.user_metadata[property];
}

function setDataFromProfile (profile) {
  const profileData = {};

  if (isPropertyNotExistInMetadata(profile, 'given_name') && profile.given_name) {
    profileData.given_name = profile.given_name;
  }

  if (isPropertyNotExistInMetadata(profile, 'family_name') && profile.family_name) {
    profileData.family_name = profile.family_name;
  }

  if (isPropertyNotExistInMetadata(profile, 'gender') && profile.gender) {
    profileData.gender = profile.gender;
  }

  if (isPropertyNotExistInMetadata(profile, 'birthdate') && profile.birthdate) {
    // support for different name and format returned by fb (and auth0?)
    const bday = profile.birthdate;
    // mm/dd/yyyy -> yyyy-mm-dd
    profileData.birthdate = `${bday.slice(6)}-${bday.slice(0, 2)}-${bday.slice(3, 5)}`;
  }

  if (isPropertyNotExistInMetadata(profile, 'worktown_id') && store.state.city && store.state.city.id) {
    profileData.worktown_id = store.state.city.id;
  }
  if (isPropertyNotExistInMetadata(profile, 'worktown_slug') && store.state.city && store.state.city.orgSlug) {
    profileData.worktown_slug = store.state.city.orgSlug;
  }

  // set user language (if not set or different before)
  if (isPropertyNotExistInMetadata('language')
    || profile.user_metadata.language !== store.state.i18n.language) {
    profileData.language = store.state.i18n.language;
  }

  return profileData;
}

function setAdditionalData (profile) {
  const additionalData = {};

  if (isPropertyNotExistInMetadata(profile, 'registered_before')) {
    const cookieReferralData = Cookies.get('referralData');
    additionalData.registered_before = true;
    additionalData.referral_data = cookieReferralData ? JSON.parse(cookieReferralData) : null;
  }

  if (typeof profile.email_verified !== 'undefined') {
    additionalData.emailVerified = profile.email_verified;
  }

  if (isPropertyNotExistInMetadata(profile, 'emailConsent')) {
    additionalData.emailConsent = store.state.auth.emailConsent;
  }

  additionalData.loginDate = new Date().toISOString()
    .slice(0, 10);

  return additionalData;
}

export async function afterLoginProcessing (userData) {
  const { profile } = userData;

  if (!store.state.auth.shouldPostProcess) {
    return;
  }

  store.dispatch('auth/disablePostProcess');
  optionalOperations(profile);
  await obligatoryOperations(profile).catch(_ => sentryCaptureException(_));
}

/**
 * @description These operations can be done asynchronous
 * and are not required for proper work of application.
 */
function optionalOperations (profile) {
  startWorkingTracking(profile).catch(_ => sentryCaptureException(_));
  trackNewSignUps(profile).catch(_ => sentryCaptureException(_));
  saveUser().catch(_ => sentryCaptureException(_));
}

/**
 * @description These operations must be done synchronous
 * because are needed for proper work of application.
 */
async function obligatoryOperations (profile) {
  await saveOnboardingData(profile);
  userUpdate(profile).catch(_ => sentryCaptureException(_));
  await calculatePrerequisitesLogic().catch(_ => sentryCaptureException(_));
}

function getPartnerSlug () {
  const { route } = router.resolve(store.state.auth.redirectPath);
  if (route.name === 'job_offer') {
    return route.params.partnerSlug;
  }

  return null;
}

async function calculatePrerequisitesLogic () {
  await userService.initUser(store.state.auth.registerSource);
  store.dispatch('auth/resetRegisterSource');
}

async function saveUser () {
  if (!store.state.auth.isReadyToSaveUser) {
    return;
  }

  await userService.saveUser({
    emailConsent: store.state.auth.emailConsent,
  });
  store.dispatch('auth/setIsReadyToSaveUser', false);
}

async function startWorkingTracking (profile) {
  const swStorageKey = storageEnum.START_WORKING_VISITOR;
  const startWorkingParams = sessionStorage.getItem(swStorageKey);

  // track start working after signup/login
  if (startWorkingParams) {
    const [cityOrgSlug, partnerOrgSlug] = startWorkingParams.split(',');

    if (profile.user_metadata && profile.user_metadata.registered_before) {
      await sendGAEvent({
        category: 'LoginAfterSW',
        action: 'pass',
      });
    } else {
      await sendGAEvent({
        category: 'SignupAfterSW',
        action: 'save',
        label: `${cityOrgSlug} / ${partnerOrgSlug}`,
      });
    }

    sessionStorage.removeItem(swStorageKey);
  }
}

async function trackNewSignUps (profile) {
  if ((!profile.user_metadata || !profile.user_metadata.registered_before)) {
    const userId = profile['custom:appjobs_user_id'];

    Cookies.set('userId', userId);

    await sendGAEvent({
      category: 'Signup',
      action: getPartnerSlug() || 'pass',
      label: store.state.city.orgSlug,
    });

    const componentName = sessionStorage.getItem('componentName');

    await sendGAEvent({
      category: 'SignupBy',
      action: componentName || 'pass',
      label: userId.replace(/\|.*/, ''),
    });

    const lastSortingOption = JSON.parse(sessionStorage.getItem('lastSortingOption'));

    if (lastSortingOption) {
      await sendGAEvent({
        category: 'Sorting',
        action: 'sorted -> sign up',
        label: lastSortingOption.label,
      });
    }
  }
}

function userUpdate (profile) {
  const profileData = setDataFromProfile(profile);
  const additionalData = setAdditionalData(profile);

  return store.dispatch('auth/updateProfile', {
    userId: profile.user_id,
    data: {
      ...profileData,
      ...additionalData,
    },
  });
}

async function saveOnboardingData (profile) {
  EventBus.$emit('setOnboardingStartingStep');
  if (store.state.auth.authTrigger === 'signIn') {
    return;
  }

  const data = JSON.parse(JSON.stringify(store.state.onboarding.guestData));
  await store.dispatch('auth/patchUserMetadata', {
    userId: profile.user_id,
    data,
  });
  await store.dispatch('onboarding/deleteGuestData');
}
