const { isFullTimeJobOffer } = require('./jobOffer');
const { MISSING_CATEGORY_ERROR } = require('../enums/errors');

class MissingCategoryError extends Error {
  constructor (message) {
    super(message);
    this.name = MISSING_CATEGORY_ERROR;
    this.message = message;
  }
}

// Function to log warning message
function logWarningMessage (message, data) {
  const strData = JSON.stringify(data);
  console.warn(`%c${message} ${strData}.`, 'font-weight: bold; font-size: 1.2em');
}

// Function to set data pairs as tags
function setDataAsTags (scope, data) {
  const pairs = Object.entries(data);
  pairs.forEach(pair => {
    scope.setTag(pair[0], pair[1]);
  });
}

// Function to capture exception
function captureException (Sentry, err, data) {
  Sentry.withScope(scope => {
    scope.setLevel('warning');
    setDataAsTags(scope, data);
    Sentry.captureException(err);
  });
}

// The main function to handle error
function sendCategoryTransErrorMessage (Sentry, data, err) {
  const message = '[content]: Missing categories translation.';
  logWarningMessage(message, data);
  captureException(Sentry, err, data);
}

function getFirstFromJobOffer (jobOffer) {
  if (!jobOffer?.categories?.length) {
    if (isFullTimeJobOffer(jobOffer)) {
      return 'moreJobsOfferCategory';
    }
    throw new MissingCategoryError('JobOffer categories not found. Probably category translation is missing.');
  }

  return jobOffer.categories[0];
}

module.exports = {
  MissingCategoryError,
  getFirstFromJobOffer,
  logWarningMessage,
  setDataAsTags,
  captureException,
  sendCategoryTransErrorMessage,
};
