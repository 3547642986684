import queryString from 'query-string';
import { get } from './request';

export default {
  getInfo (params = {}) {
    let paramsStr = '';

    if (params) {
      paramsStr = `?${queryString.stringify(params)}`;
    }

    return get(`/city_categories${paramsStr}`, { authorize: false, silenceErrors: true });
  },

  getCityCategoriesByCity (citySlug, sort = '') {
    return get(`/city_categories?city_slug=${citySlug}&sort=${sort}`, { authorize: false, silenceErrors: true });
  },

  getAllCategories (citySlug) {
    return get(`/city_categories?city_slug=${citySlug}`, { authorize: false, silenceErrors: true });
  },
};
