<template>
  <dropdown-menu
    v-show="parsedLanguageList.length > 1"
    type="links"
    :list="parsedLanguageList"
    name="language-selector"
    :prevent-self-toggle="true"
    @change="redirectLanguage($event[0])"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { replaceCityPlaceholder } from '@/js/helpers/str';
import DropdownMenu from '@/js/components/ui/dropdown-menu';
import { storeEvent } from '../../helpers/analytics';

export default {
  name: 'LanguageSelector',

  components: {
    DropdownMenu,
  },

  computed: {
    ...mapState({
      languageList: state => state.i18n.list,
      currentLanguage: state => state.i18n.language,
      cityName: state => state.city.name,
    }),

    parsedLanguageList () {
      return this.languageList.map(item => ({
        ...item,
        title: replaceCityPlaceholder(item.title, this.cityName),
      }));
    },
  },

  methods: {
    ...mapActions('cities', ['clearPrePopulatedCities']),

    redirectLanguage (redirectData) {
      this.clearPrePopulatedCities();

      storeEvent({
        category: 'LanguageSwitch',
        action: 'click',
        label: `${this.currentLanguage}-${redirectData.id}`,
      });

      location.href = redirectData.uri;
    },
  },
};
</script>
