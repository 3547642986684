import buildParams from '@/js/helpers/buildParams';
import phrasesService from '../../services/phrases';
import i18nService from '../../services/i18n';
import languageList from '../../helpers/languageList';

const mutations = {
  setLanguage (stateObj, language) {
    stateObj.language = language;
  },

  setListLanguage (stateObj, isTranslatable) {
    let address = 'http://localhost:8080';

    if (process.env.VUE_APP_STAGE) {
      address = '';
    } else if (process.env.NODE_ENV === 'production') {
      address = 'https://www.appjobs.com';
    }

    stateObj.list = languageList.filter(item => !item.hidden);
    stateObj.list.forEach(item => {
      item._select = item.id === stateObj.language;
      const redirectLink = stateObj.redirectLinks.find(redirect => redirect.locale === item.id);
      if (isTranslatable && redirectLink) {
        item.uri = `${address}${redirectLink.uri}`;
      } else {
        item.uri = item.id === 'en' ? address : `${address}/${item.id}`;
      }

      if (redirectLink && redirectLink.title) {
        item.title = redirectLink.title;
      }
    });
  },

  setPhrases (stateObj, phrases) {
    stateObj.phrases = phrases;
  },

  setRedirectLinks (stateObj, links) {
    stateObj.redirectLinks = links || [];
  },

  setIsLanguageSet (stateObj) {
    stateObj.isLanguageSet = true;
  },
};

const actions = {
  async setLanguage ({
    commit, dispatch, state, rootGetters, rootState,
  }, language) {
    const { isUser } = rootGetters;
    const { profile } = rootState.auth;

    if (isUser && language !== state.language) {
      dispatch('auth/updateProfile', {
        userId: profile.user_id,
        data: { language },
      }, {
        root: true,
      });
    }

    if (state.phrases === null || state.language !== language) {
      commit('setLanguage', language);
      commit('setIsLanguageSet');
      await dispatch('getPhrases');
    }

    if (!state.isLanguageSet) {
      commit('setIsLanguageSet');
    }
  },

  async setListLanguage ({ commit, dispatch }, transParams = {}) {
    const isTranslatable = Object.keys(transParams).length > 0;
    if (isTranslatable) {
      await dispatch('getRedirectLinks', transParams);
    }
    commit('setListLanguage', isTranslatable);
  },

  async getPhrases ({ commit, dispatch }) {
    if (prePopulated && prePopulated.phrases) {
      commit('setPhrases', prePopulated.phrases);
      prePopulated.phrases = null;
    } else {
      await dispatch('getClientSidePhrases');
    }
  },

  async getRedirectLinks ({ commit, rootState }, params) {
    // Params are set in router meta data: src/js/router/index.js
    commit('setRedirectLinks', await i18nService.getRedirectLinks(buildParams(params, rootState)));
  },

  async getClientSidePhrases ({ commit }) {
    commit('setPhrases', await phrasesService.getPhrases());
  },
};

const state = {
  language: 'en',
  list: [],
  phrases: null,
  redirectLinks: [],
  isLanguageSet: false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
