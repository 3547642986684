// language id uses ISO 639-1 standard:  https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// Remember to edit web/src/ssr/src/routing.js and api/config/initializers/i18n.rb
// Convenience methods: languageList.map(l => l.id); languageList.map(l => l.id).join(' ');
module.exports = [
  { name: 'Deutsch', id: 'de', title: 'App-basierte Teilzeitjobs / Minijobs / Studentenjobs' },
  {
    name: 'English', application: true, id: 'en', title: 'Part-time / weekend / student app based jobs',
  },
  {
    name: 'Español', application: true, id: 'es', title: 'App-trabajos de medio tiempo/ fines de semana / para estudiantes',
  },
  {
    name: 'Français', id: 'fr', application: true, title: 'Emplois temps partiel / week-end / pour étudiants',
  },
  { name: 'Italiano', id: 'it', title: 'Lavoro part time / lavori per studenti' },
  { name: 'Nederlands', id: 'nl', title: 'App-gebaseerde parttime werk / weekendwerk' },
  { name: 'Polski', id: 'pl', title: 'Praca dodatkowa / praca weekendowa / praca dla studenta' },
  { name: 'Português', id: 'pt', title: 'Vagas de emprego meio periodo' },
  { name: 'Svenska', id: 'sv', title: 'Appbaserade Lediga Jobb / Extrajobb / Deltidsjobb' },
  { name: 'Türkçe', id: 'tr', title: 'app bazlı yarı zamanlı/ haftasonu / öğrenci işleri' },
];
