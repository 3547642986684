import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['profile']),
    ...mapGetters(['isUser']),

    profileMixin_isEditableProvider () {
      if (!this.profile || !this.profile['custom:appjobs_user_id']) {
        return false;
      }

      const provider = this.profile['custom:appjobs_user_id']
        .toLowerCase()
        .split('|')[0] || '';
      const editableProviders = ['cognito', 'auth0'];

      return editableProviders.includes(provider);
    },

    profileMixin_profileData () {
      return (this.profile?.user_metadata) || {};
    },

    profileMixin_email () {
      if (!this.isUser) {
        return '';
      }

      return this.profile.email || '';
    },

    profileMixin_givenName () {
      let name = '';
      if (this.profile?.given_name) {
        name = this.profile.given_name;
      }

      if (this.profileMixin_isEditableProvider) {
        return this.profileMixin_profileData.given_name || name;
      }

      return name;
    },

    profileMixin_familyName () {
      let name = '';
      if (this.profile?.family_name) {
        name = this.profile.family_name;
      }

      if (this.profileMixin_isEditableProvider) {
        return this.profileMixin_profileData.family_name || name;
      }

      return name;
    },

    profileMixin_fullName () {
      if (!this.isUser) {
        return 'Anonymous';
      }

      return (this.profileMixin_givenName || this.profileMixin_familyName)
        ? `${this.profileMixin_givenName} ${this.profileMixin_familyName}`.trim() : '';
    },

    profileMixin_shortName () {
      const firstName = this.profileMixin_givenName;
      const lastNameInitial = this.profileMixin_familyName[0];
      const combinedNames = `${firstName || ''} ${lastNameInitial || ''}`.trim();

      if (firstName || lastNameInitial) {
        return `${combinedNames}.`;
      }

      return '';
    },

    profileMixin_birthdate () {
      return this.profileMixin_profileData.birthdate || '';
    },

    profileMixin_worktownId () {
      return this.profileMixin_profileData.worktown_id;
    },

    profileMixin_worktownSlug () {
      return this.profileMixin_profileData.worktown_slug;
    },

    profileMixin_country () {
      return this.profileMixin_profileData.country;
    },

    /**
     * Gives avatar only if set by user
     * @returns String
     */
    profileMixin_avatar () {
      if (this.profileMixin_profileData.avatar) {
        return this.profileMixin_profileData.avatar;
      }

      if (this.profile?.picture) {
        try {
          const parsed = JSON.parse(this.profile.picture);

          // facebook avatar
          if (parsed.data?.url) {
            return parsed.data.url;
          }
        } catch {
          // google avatar
          return this.profile.picture;
        }
      }

      return '';
    },

    profileMixin_phone () {
      return this.profileMixin_profileData.phone || '';
    },

    profileMixin_gender () {
      return this.profileMixin_profileData.gender || '';
    },

    profileMixin_genderName () {
      const gender = this.profileMixin_profileData.gender || '';
      const foundGender = this.profileMixin_genderList.find(genderItem => genderItem.id === gender);

      return foundGender && foundGender.name;
    },

    profileMixin_genderList () {
      return [
        {
          id: 'other',
          name: this.translate('profile.gender.unset'),
        },
        {
          id: 'male',
          name: this.translate('profile.gender.male'),
        },
        {
          id: 'female',
          name: this.translate('profile.gender.female'),
        },
      ];
    },

    profileMixin_favouriteCategories () {
      return this.profileMixin_profileData.favourite_categories || [];
    },

    profileMixin_hasFavouriteCategories () {
      return !!this.profileMixin_favouriteCategories.length;
    },

    profileMixin_isNewUser () {
      return (!this.profile.user_metadata
        || (this.profile.user_metadata && this.profile.logins_count === 1));
    },

    profileMixin_userId () {
      return this.profile.user_id;
    },

    profileMixin_typeOfJobs () {
      return this.profileMixin_profileData.type_of_jobs || [];
    },

    profileMixin_workFromHome () {
      return this.profileMixin_profileData.work_from_home || false;
    },
  },
};
