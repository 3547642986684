import { get } from './request';

export default {
  getPosts (params = {}) {
    return get('/posts/', { authorize: false, errorMessage: 'Cannot load blog posts.', params });
  },

  getPost (slug) {
    return get(`/posts?slug=${slug}&expand=body,city`, { authorize: false, errorMessage: 'Cannot load blog post.' }).then(posts => posts[0]);
  },

  getCityPost (cityId) {
    return get(`/posts?city_id=${cityId}&limit=1`, { authorize: false, silenceErrors: true }).then(posts => posts[0]);
  },

  getSimilarPosts (postId) {
    return get(`/posts/similar?id=${postId}`);
  },

  getJobOfferPosts (citySlug, partnerSlug) {
    return get(`/posts/job_offer?city_slug=${citySlug}&partner_slug=${partnerSlug}`, { authorize: false });
  },
};
