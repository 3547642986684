export default [
  {
    name: 'user-desk-index',
    path: 'my-desk',
    component: () => import('@/js/views/UserDesk/Index'),

    redirect: () => ({ name: 'user-desk' }),

    children: [
      {
        name: 'user-desk',
        path: 'jobs',
        component: () => import('@/js/views/UserDesk/Jobs'),
      },
    ],
  },
];
