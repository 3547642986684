function isRobot (userAgent, headers) {
  const ua = userAgent || (typeof navigator !== 'undefined' ? navigator.userAgent : null);
  const fromHeader = headers?.From || headers?.from;

  // If no userAgent is available, assume it's not a bot
  if (!ua) {
    return false;
  }

  // Regular expression to catch known bots including Google bots
  const botPattern = /bot|crawler|spider|crawling|googlebot|adsbot-google|mediapartners-google|googleother/i;

  // Check user agent for bot patterns
  const isBotUserAgent = botPattern.test(ua);

  // Check 'From' header to detect Googlebot or other known bots
  const isGoogleFromHeader = fromHeader && /googlebot\(at\)googlebot\.com/i.test(fromHeader);

  // Return true if either the user-agent or the 'From' header matches known bot patterns
  return isBotUserAgent || isGoogleFromHeader;
}

function blockRobotOnHTMLAssets (html) {
  if (!isRobot()) {
    return html;
  }

  return html
    .replace(/<figure.+?<\/figure>/gi, '')
    .replace(/<img.+?https:\/\/res\.cloudinary\.com.+?\/>/gi, '');
}

module.exports = {
  isRobot,
  blockRobotOnHTMLAssets,
};
