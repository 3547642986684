/**
 * Converts a camelCase string to a dash-case (kebab-case) string.
 * @param {string} camelCaseString - The camelCase string to convert.
 * @return {string} The converted dash-case string.
 */
const camelToDashCase = camelCaseString => camelCaseString.replace(/[\w]([A-Z])/g, match => `${match[0]}-${match[1]}`).toLowerCase();

/**
 * Converts a string to title case.
 * @param {string} inputString - The string to convert.
 * @return {string} The converted title case string.
 */
const toTitleCase = inputString => {
  const wordsArray = inputString.toLowerCase().split(' ');
  const titleCasedWords = wordsArray.map(word => word.charAt(0).toUpperCase() + word.substring(1));

  return titleCasedWords.join(' ');
};

/**
 * Capitalizes the first letter of a string.
 * @param {string} inputString - The string to capitalize.
 * @return {string} The capitalized string or empty string if the input was not a string type
 */
const capitalize = inputString => (typeof inputString !== 'string' ? ''
  : inputString.charAt(0).toUpperCase() + inputString.slice(1));

/**
 * Truncate the input string to the specified length, adding an ellipsis if truncated.
 *
 * @param {string} inputString - The string to truncate.
 * @param {number} maxlength - The maximum length of the truncated string, including the ellipsis.
 * @returns {string} - The truncated string.
 */
const truncate = (inputString, maxlength) => {
  if (typeof inputString !== 'string' || typeof maxlength !== 'number' || maxlength <= 0) {
    return 'Invalid input';
  }

  if (inputString.length <= maxlength) {
    return inputString;
  }

  const truncatedString = `${inputString.slice(0, maxlength - 1)}…`;
  return truncatedString;
};

/**
 * Replaces multiple consecutive slashes in a string with a single slash.
 * @param {string} inputString - The string to process.
 * @return {string} The processed string.
 */
const multipleSlashesToSingle = inputString => {
  if (!inputString) {
    return inputString;
  }

  return inputString.replace(/\/\/+/g, '/');
};

/**
 * Removes a trailing slash from the end of a string, if one exists.
 * @param {string} inputString - The string from which to remove the trailing slash.
 * @return {string} The string without a trailing slash.
 * @throws {TypeError} If the input is not a string.
 */
const removeSlashFromTheEnd = inputString => {
  if (typeof inputString !== 'string') {
    throw new TypeError('The argument must be a string.');
  }

  return inputString.endsWith('/') ? inputString.slice(0, -1) : inputString;
};

/**
 * Converts a string to a URL-friendly "slug".
 * @param {string} inputString - The string to convert.
 * @param {string} replacementSymbol - The symbol that will replace spaces; hyphen by default.
 * @return {string} The slugified string.
 */
const slugify = (inputString, replacementSymbol = '-') => {
  if (inputString === null || inputString === undefined) {
    throw new TypeError('The argument must not be null or undefined.');
  }

  // Create a regex pattern to match all non-word characters except the replacementSymbol
  const nonWordCharRegex = new RegExp(`[^\\w\\${replacementSymbol}]+`, 'g');

  return inputString
    .toString()
    .normalize('NFKD') // Normalize string, removing diacritics
    .toLowerCase()
    .trim()
    .replace(/\s+/g, replacementSymbol) // Replace spaces with replacement symbol
    .replace(nonWordCharRegex, '') // Remove all non-word chars
    .replace(/-+/g, replacementSymbol); // Replace multiple hyphens with a replacement symbol
};

/**
 * Converts a slugified string back to its original form.
 * @param {string} slugifiedString - The slugified string to convert.
 * @param {string} replacementSymbol - The symbol that was used to replace spaces; hyphen by default
 * @return {string} The unslugified string.
 */
const unslugify = (slugifiedString, replacementSymbol = '-') => {
  if (slugifiedString === null || slugifiedString === undefined) {
    throw new TypeError('The argument must not be null or undefined.');
  }

  return slugifiedString.replace(new RegExp(`\\${replacementSymbol}`, 'g'), ' ');
};

/**
 * Replace the placeholder `%{cityName}` in a string with the provided city name.
 * @param {string} inputString - The original string containing the placeholder.
 * @param {string} cityName - The city name to replace the placeholder with.
 * @return {string} The processed string.
 */
const replaceCityPlaceholder = (inputString, cityName) => {
  const placeholder = /%{cityName}/g;
  return inputString.replace(placeholder, cityName);
};

/**
 * Breaks down a US city's full name into its name and state.
 * @param {string} cityFullName - The city full name, including the state (e.g., "Miami, FL").
 * @return {Object} An object containing the city name and state.
 */
const breakDownUsCityName = (cityFullName = '') => {
  const parts = cityFullName.split(',').map(part => part.trim());
  const cityName = parts[0] || '';
  let stateName = '';

  if (parts.length > 1) {
    stateName = parts[1].toUpperCase();
  }

  return {
    name: cityName,
    state: stateName,
  };
};

/**
 * Constructs a full city name string from city details.
 * The string is composed of the city's name and state, separated by a comma.
 * If either the city name or state is not provided, it constructs the label
 * with the available information.
 *
 * @param {Object} [city] - The city details object (optional).
 * @param {string} [city.name] - The name of the city (optional).
 * @param {string} [city.state] - The state of the city (optional).
 * @returns {string} A label string composed of the city's name and state
 *                   or an empty string if no city details are provided.
 */

const constructFullCityName = city => {
  if (!city) return '';
  const parts = [city.name, city.state].filter(Boolean);
  return parts.join(', ');
};

/**
 * Strips HTML tags from a string. Line breaks (<br> or <BR>) are replaced with '\n'.
 * @param {string} inputString - The string from which to remove HTML tags.
 * @return {string} The string without HTML tags.
 */
const stripHTML = inputString => {
  if (inputString === null || inputString === undefined) {
    throw new TypeError('The argument must not be null or undefined.');
  }

  return inputString
    .replace(/<br>/ig, '\n') // Replace line breaks with '\n'
    .replace(/<[^>]+?>/g, ''); // Remove all other HTML tags
};

/**
 * Constructs a URL based on provided language and URL components.
 * @param {string} language - The language to include in the URL. 'en' is excluded.
 * @param {...string} urlArguments - The URL components to include in the URL.
 * @return {string} The constructed URL.
 */
const getUrlFromArray = (language, ...urlArguments) => {
  const urlArray = ['https://www.appjobs.com'];

  if (language !== 'en') {
    urlArray.push(language);
  }

  urlArray.push(...urlArguments.filter(Boolean));

  return urlArray.join('/');
};

/**
 * Transforms the keys of the given object to lowercase.
 *
 * @param {Object} obj - The object whose keys are to be transformed.
 * @returns {Object} A new object with the same values but with all keys in lowercase.
 */
function transformKeysToLowercase (obj) {
  const result = {};
  Object.entries(obj).forEach(([key, value]) => {
    result[key.toLowerCase()] = value;
  });
  return result;
}

module.exports = {
  camelToDashCase,
  toTitleCase,
  capitalize,
  truncate,
  multipleSlashesToSingle,
  removeSlashFromTheEnd,
  slugify,
  unslugify,
  replaceCityPlaceholder,
  breakDownUsCityName,
  constructFullCityName,
  stripHTML,
  getUrlFromArray,
  transformKeysToLowercase,
};
