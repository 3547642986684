<template>
  <div class="infographic ui equal width stackable grid">
    <template v-for="step in 3">
      <div
        :key="`image${step}`"
        class="center aligned four wide no padding column"
      >
        <img
          v-lazy="src(step)"
          :data-srcset="srcset(step)"
          class="image"
          :alt="`Step ${step}`"
        >
        <div class="text">
          {{ translate(`${textPrefix}.step${step}`) }}
        </div>
      </div>
      <hr
        v-if="step < 3"
        :key="`hr${step}`"
        class="one wide tablet only computer only separator column"
      >
    </template>
  </div>
</template>

<style lang="less" scoped>
  .infographic.ui.grid {
    > .column:not(.row) {
      &[class*="no padding"] {
        padding-left: 0;
        padding-right: 0;
      }
      &.separator {
        margin: auto;
        margin-top: 80px;
        padding: 0;
      }
      .text {
        font-size: 20px;
      }
    }

    &.small {
      > .column:not(.row) {
        &.separator {
          margin-top: 58px;
        }
        .text {
          font-size: 16px;
        }
      }
    }

    &.small-mobile {
      > .column:not(.row) .text {
        @media all and (max-width: @largestMobileScreen) {
          font-size: 16px;
        }
      }
    }

    &.emphasis {
      .text {
        font-weight: bold;
      }
    }
  }
</style>

<script>
export default {
  name: 'InfographicSection',

  props: {
    type: { type: String, default: 'how' },
    textPrefix: { type: String, default: 'howItWorks' },
  },

  methods: {
    src (step) {
      return `/static/infographic/${this.type}${step}_1x.png`;
    },

    srcset (step) {
      return `${this.src(step)}, /static/infographic/${this.type}${step}_2x.png 2x`;
    },
  },
};
</script>
