import { isRobot } from '@/js/helpers/robot';
import geoService from '@/js/services/geo';
import citiesService from '@/js/services/cities';

const getters = {
  hasCities (stateObj) {
    return stateObj.cities && stateObj.cities.length;
  },

  hasNearbyCities (stateObj) {
    return stateObj.nearbyCities && stateObj.nearbyCities.length;
  },
};

const mutations = {
  startLoadingCities (stateObj) {
    stateObj.isLoadingCities = true;
  },

  stopLoadingCities (stateObj) {
    stateObj.isLoadingCities = false;
  },

  startLoadingTopCities (stateObj) {
    stateObj.isLoadingTopCities = true;
  },

  stopLoadingTopCities (stateObj) {
    stateObj.isLoadingTopCities = false;
  },

  startLoadingNewestCities (stateObj) {
    stateObj.isLoadingNewestCities = true;
  },

  stopLoadingNewestCities (stateObj) {
    stateObj.isLoadingNewestCities = false;
  },

  startLoadingNearbyCities (stateObj) {
    stateObj.isLoadingNearbyCities = true;
  },

  stopLoadingNearbyCities (stateObj) {
    stateObj.isLoadingNearbyCities = false;
  },

  setCities (stateObj, cities) {
    stateObj.cities = cities;
  },

  setTopCities (stateObj, cities) {
    stateObj.topCities = cities;
  },

  setNewestCities (stateObj, cities) {
    stateObj.newestCities = cities;
  },

  setNearbyCities (stateObj, cities) {
    stateObj.nearbyCities = cities;
  },
};

const actions = {
  async getCities ({ commit, state }) {
    if (state.isLoadingCities || state.cities.length) {
      return;
    }

    commit('startLoadingCities');
    if (prePopulated && prePopulated.cities && prePopulated.cities.length > 1) {
      commit('setCities', prePopulated.cities);
    } else {
      const cities = await citiesService.getCities() || [];
      commit('setCities', cities.filter(city => !city.isRedirection));
    }

    commit('stopLoadingCities');
  },

  async getTopCities ({ commit, state }) {
    if (state.isLoadingTopCities) {
      return;
    }

    commit('startLoadingTopCities');
    if (prePopulated && prePopulated.topCities) {
      commit('setTopCities', prePopulated.topCities);
    } else {
      commit('setTopCities', await citiesService.getTopCities());
    }
    commit('stopLoadingTopCities');
  },

  async getNewestCities ({ commit, state }) {
    if (state.isLoadingNewestCities) {
      return;
    }

    commit('startLoadingNewestCities');
    if (prePopulated && prePopulated.newestCities) {
      commit('setNewestCities', prePopulated.newestCities);
    } else {
      commit('setNewestCities', await citiesService.getNewestCities());
    }
    commit('stopLoadingNewestCities');
  },

  async getNearbyCities ({ commit, state }, limit) {
    if (state.isLoadingNearbyCities || isRobot() || state.nearbyCities.length) {
      return;
    }

    commit('startLoadingNearbyCities');
    const { lat, lng } = await geoService.getCoordinates();

    commit('setNearbyCities', await citiesService.getNearbyCities({ lat, lng, limit }));
    commit('stopLoadingNearbyCities');
  },

  clearPrePopulatedCities () {
    prePopulated.cities = null;
  },
};

const state = {
  cities: [],
  topCities: [],
  newestCities: [],
  nearbyCities: [],
  isLoadingCities: false,
  isLoadingTopCities: false,
  isLoadingNewestCities: false,
  isLoadingNearbyCities: false,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
