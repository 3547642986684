import { AUTH } from '@/js/enums/modal';
import { translate } from '@/js/helpers/i18n';

const mutations = {
  setEmail (stateObj, email) {
    stateObj.email = email;
  },

  setToken (stateObj, token) {
    stateObj.token = token;
  },

  setSecretHash (stateObj, secretHash) {
    stateObj.secretHash = secretHash;
  },
};

const actions = {
  setEmail: ({ commit }, email) => {
    commit('setEmail', email);
  },

  setToken: ({ commit }, token) => {
    commit('setToken', token);
  },

  setSecretHash: ({ commit }, secretHash) => {
    commit('setSecretHash', secretHash);
  },

  handleTooManyRequests ({ dispatch }) {
    dispatch('setMessage', {
      message: translate('reset_password.too_many_requests'),
      variation: 'error',
    }, { root: true });

    dispatch('modal/showModal', {
      name: AUTH,
      data: { initialScreen: 'login' },
    }, { root: true });
  },

  resetState ({ commit }) {
    commit('setEmail', null);
    commit('setToken', null);
    commit('setSecretHash', null);
  },
};

const state = {
  email: null,
  token: null,
  secretHash: null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
