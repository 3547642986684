/*
  This kind of hack allows us to easily mock functions which are used in scope of same module,
  without that it's really problematic or even impossible to do that
  more information regarding that problem can be found here:
  https://newbedev.com/how-to-mock-functions-in-the-same-module-using-jest
 */
/* eslint import/no-self-import: "off" */
import Auth from '@aws-amplify/auth';
import store from '@/js/store';
import authService from '@/js/services/auth';
import * as thisModule from './emailVerification';

export async function checkEmailVerification (userData) {
  if (!userData || !userData.attributes) {
    return;
  }

  if (userData?.attributes?.email_verified) {
    return;
  }

  const verificationCode = store.state.auth.verificationCode;
  if (verificationCode) {
    await thisModule.verifyUserEmail(userData, verificationCode);
  } else if (userData && !(userData?.attributes['custom:verify_email_send'])) {
    await thisModule.sendVerificationEmail(userData);
  }
}

export async function verifyUserEmail (userData, code) {
  try {
    await authService.postVerifyEmailConfirm({
      email: userData?.attributes?.email,
      token: code,
    });
    store.commit('auth/setVerificationCode', null);
    store.commit('auth/setAuthErrorMessage', '');
  } catch (e) {
    store.commit('auth/setAuthErrorMessage', e.message);
    console.error('Verification Failed', e);
  }
}

export async function sendVerificationEmail (userData) {
  try {
    await authService.postVerifyEmailSend({
      email: userData?.attributes?.email,
    });
    await Auth.updateUserAttributes(userData, {
      'custom:verify_email_send': '1',
    });
    store.commit('auth/setAuthErrorMessage', '');
  } catch (e) {
    store.commit('auth/setAuthErrorMessage', e.message);
    console.error('Verification Failed', e);
  }
}
