<template>
  <div class="auth-button">
    <user-menu
      v-if="context === 'topnavigation'"
      :avatar-url="profileMixin_avatar"
      :context="context"
    />
  </div>
</template>

<style lang="less" scoped>
  .auth-button {
    display: flex;
    align-items: center;
    text-align: center;

    &.hidden {
      display: none;
    }

    @media only screen and (min-width: @largestMobileScreen) {
      .user-menu {
        transition: all 800ms ease;
      }
    }
  }
  .headers {
    .auth-button {
      @media only screen and (min-width: @largestMobileScreen) {
        margin-left: 30px;
      }
    }
  }
</style>

<script>
import userMenu from '@/js/components/user-menu';
import profileMixin from '@/js/mixins/profile-mixin';

export default {
  name: 'AuthButton',

  components: {
    userMenu,
  },

  mixins: [profileMixin],

  props: {
    context: String,
  },
};
</script>
