const mutations = {
  setGuestData (stateObj, guestData) {
    stateObj.guestData = guestData;
  },
};

const actions = {
  storeGuestData ({ commit, state }, guestData) {
    commit('setGuestData', { ...state.guestData, ...guestData });
  },

  deleteGuestData ({ commit }) {
    commit('setGuestData', {});
  },
};

const getters = {
  guestData (stateObj) {
    return stateObj.guestData;
  },
};

const state = {
  guestData: {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
