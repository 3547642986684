<template>
  <div>
    <aj-loader
      :show="isCityCategoryLoading"
      class="componentLoader"
    />
    <city-category />
  </div>
</template>

<script>
import AjLoader from '@appjobs/ui/src/components/AjLoader/AjLoader';
import { mapState } from 'vuex';
import { EventBus } from '@/js/helpers/utils';
import { seoTitle } from '../helpers/seo';
import { setContentGrouping } from '../helpers/analytics';
import store from '../store';
import { translate } from '../helpers/i18n';

function setStoreData (cityCategory) {
  store.dispatch('city/setCity', cityCategory.city);
  store.dispatch('cityCategory/setData', cityCategory);
}

function setSeoTitle (cityCategory, { page }) {
  let title = cityCategory.header || translate('seo.title.cityCategory', {
    cityName: cityCategory.city.name,
    categoryName: cityCategory.category.name,
  });
  page = page ? parseInt(page.split('page-')[1]) : 1;

  if (page > 1) {
    title += ` - ${translate('pagination.page', { number: page })}`;
  }
  seoTitle(title);
}

async function processFilters (route) {
  EventBus.$emit('clearJobOffersFilters');
  await processJobOffersFilteredCategories();
  await processJobOffersFilteredType(route);
}

async function processJobOffersFilteredCategories () {
  await store.dispatch('jobOffers/clearFilteredCategories');
}

async function processJobOffersFilteredType (route) {
  const filteredType = route.query.type;
  if (filteredType) {
    await store.dispatch('jobOffers/setFilteredType', filteredType);
  } else {
    await store.dispatch('jobOffers/clearFilteredType');
  }
}

export default {
  name: 'CityCategoryRoute',

  data () {
    return {
      isCityCategoryLoading: true,
    };
  },

  computed: {
    ...mapState(['cityCategory']),
  },

  created () {
    EventBus.$on('city-category-loaded', () => {
      this.isCityCategoryLoading = false;
    });
  },

  async beforeRouteEnter (to, from, next) {
    await store.dispatch('cityCategory/getCityCategoryInfo', to.params);
    await processFilters(to);
    const { cityCategory } = store.state.cityCategory;

    setSeoTitle(cityCategory, to.params);
    setStoreData(cityCategory);
    setContentGrouping({
      index: 2,
      group: cityCategory.category.orgSlug,
    });

    next();
  },

  async beforeRouteUpdate (to, from, next) {
    await store.dispatch('cityCategory/getCityCategoryInfo', to.params);
    const { cityCategory } = store.state.cityCategory;

    setSeoTitle(cityCategory, to.params);
    setStoreData(cityCategory);

    next();
  },

  components: {
    AjLoader,
    CityCategory: () => import('@/js/views/CityCategory'),
  },
};
</script>

<style lang="scss" scoped>
  .componentLoader {
    min-height: 100vh;
    position: relative;
  }
</style>
