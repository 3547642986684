const mutations = {
  hideCookieNotice (stateObj) {
    stateObj.hidden = true;
  },

  setHeight (stateObj, value) {
    stateObj.cookieNoticeHeight = value;
  },

  allowAllCookies (stateObj) {
    Object.keys(stateObj.cookiesConsentForm).forEach(key => {
      stateObj.cookiesConsentForm[key] = true;
    });
  },
};

const state = {
  hidden: false,
  cookieNoticeHeight: null,
  cookiesConsentForm: {
    essential: true,
    preferences: false,
    analytics: false,
    marketing: false,
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
