<template>
  <aj-card
    v-if="offersToShow.length"
    shadow
    class="interlinking__item"
  >
    <aj-text
      subtitle
      class="interlinking__itemHeadline"
      v-text="translate('interlinking.popular_jobs')"
    />
    <ul>
      <li
        v-for="(offer, index) in offersToShow"
        :key="index"
      >
        <router-link
          :to="offerRoute(offer)"
        >
          <aj-text tag="span">
            {{ offer.cardTitle }}
          </aj-text>
          <aj-icon
            width="12"
            shape="arrow-right-1"
          />
        </router-link>
      </li>
    </ul>
  </aj-card>
</template>

<script>
import { mapActions } from 'vuex';
import AjCard from '@appjobs/ui/src/components/AjCard/AjCard';
import AjIcon from '@appjobs/ui/src/components/AjIcon/AjIcon';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import jobOffersService from '@/js/services/job-offers';
import seoService from '@/js/services/seo';

export default {
  components: {
    AjCard,
    AjIcon,
    AjText,
  },

  data () {
    return {
      offersToShow: [],
    };
  },

  methods: {
    ...mapActions('jobOffers', ['getOffers']),

    async prepareOffers () {
      const offers = await jobOffersService.getOffers({
        city_slug: this.$route.params.citySlug,
        limit: 6,
      });
      this.offersToShow = await seoService.getOffersSeoData(offers);
    },

    offerRoute (offer) {
      return {
        name: 'job_offer',
        params: {
          citySlug: offer.city.slug,
          partnerSlug: offer.partner.slug,
        },
      };
    },
  },

  created () {
    this.prepareOffers();
  },
};
</script>
