import blogService from '../../services/blog';

const mutations = {
  isLoading (stateObj, isLoading) {
    stateObj.isLoading = isLoading;
  },

  setPosts (stateObj, posts) {
    stateObj.posts = posts;
  },

  setPost (stateObj, post) {
    stateObj.post = post;
  },

  setJobOfferPosts (stateObj, { key, posts }) {
    stateObj.jobOfferPosts[key] = posts;
  },

  setJobOfferPostsKey (stateObj, key) {
    stateObj.jobOfferPostsKey = key;
  },
};

const actions = {
  startLoading ({ commit }) {
    commit('isLoading', true);
  },

  stopLoading ({ commit }) {
    commit('isLoading', false);
  },

  async getPosts ({ dispatch, commit }) {
    dispatch('startLoading');
    if (prePopulated && prePopulated.posts) {
      commit('setPosts', prePopulated.posts);
      prePopulated.posts = null;
    } else {
      commit('setPosts', await blogService.getPosts());
    }
    dispatch('stopLoading');
  },

  async getPost ({ state, dispatch, commit }, slug) {
    // Early exit if opening the same blog post again
    if (state && state.post && state.post.slug && state.post.slug === slug) {
      return;
    }

    dispatch('startLoading');
    if (prePopulated && prePopulated.post && prePopulated.post[0]) {
      commit('setPost', prePopulated.post[0]);
      prePopulated.post = null;
    } else {
      commit('setPost', await blogService.getPost(slug));
    }
    dispatch('stopLoading');
  },

  async getJobOfferPosts (context, payload) {
    const { commit, dispatch, state } = context;
    const { language, citySlug, partnerSlug } = payload;
    const key = `${language}_${citySlug}_${partnerSlug}`;

    dispatch('startLoading');
    commit('setJobOfferPostsKey', key);
    if (state.jobOfferPosts[key]) {
      return;
    }

    const posts = await blogService.getJobOfferPosts(citySlug, partnerSlug);
    commit('setJobOfferPosts', { key, posts });
    dispatch('stopLoading');
  },
};

const state = {
  isLoading: false,
  posts: [],
  post: {},
  jobOfferPosts: {},
  jobOfferPostsKey: '',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
