import store from '@/js/store';
import { get } from './request';

const getLanguage = () => store.state.i18n.language;

export default {
  getRedirectLinks (params) {
    return get(`/seo/${getLanguage()}/redirect-link`, { params, target: 'bse-api' });
  },
};
