<template>
  <div
    v-lazydynamic
    v-vod
    v-html="formattedHtml"
  />
</template>

<script>
import lazyLoadDynamic from '../directives/lazyLoadDynamic';
import videoOnDemand from '../directives/videoOnDemand';

export default {
  name: 'OverviewContent',

  directives: {
    lazydynamic: lazyLoadDynamic,
    vod: videoOnDemand,
  },

  props: {
    html: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedHtml () {
      return this.prepareHtmlArray(this.html).join('');
    },
  },

  methods: {
    /**
       * Parses HTML data and makes every paragraph a proper <p> tag or <h2> tag. It is easier
       * to build excerpt out of such gathered data. Every entry is an array item.
       *
       * In most cases first entry is a <strong> tag being transcoded to <h2>. Every raw "paragraph"
       * is separated with  double <br> that's why this is taken as a split param.
       *
       * @param html String   This comes from API as raw html text converted from markdown
       * @returns Array|undefined
       */
    prepareHtmlArray (html) {
      if (!html) {
        return undefined;
      }

      const htmlParagraphs = html.split('<br><br>');
      const combinedHtml = [];

      htmlParagraphs.forEach((paragraph, index) => {
        if (index === 0 && paragraph.indexOf('<strong>') === 0) {
          const stripped = paragraph.replace(/<\/?strong\>/g, '');

          combinedHtml.push(`<h2>${stripped}</h2>`);

          return undefined;
        }
        combinedHtml.push(`<p>${paragraph}</p>`);

        return undefined;
      });

      return combinedHtml;
    },
  },
};
</script>
